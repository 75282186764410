import { IonCol, IonFab, IonFabButton, IonIcon, IonRow } from '@ionic/react';
import { pauseOutline, playBackOutline, playForwardOutline, playOutline } from 'ionicons/icons';
import React from 'react';

type Props = {
    showPlayer: boolean;
    audioToPlay?: string,

    onPlayPause?: () => void,

    onForwardPressed?: () => void,
    onBackwardPressed?: () => void,

    onPlaybackEnded?: () => void,
}

type State = {
    audioPlayer: HTMLAudioElement | undefined,
    isPlayingAudio: boolean,
}

class TtsPlayer extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            audioPlayer: undefined,
            isPlayingAudio: false,
        }
    }

    componentDidMount() {
        const audioPlayer = new Audio('');
        audioPlayer.onplay = () => {
            this.setState({isPlayingAudio: true });
        };
        audioPlayer.onpause = () => {
            this.setState({isPlayingAudio: false });
        };
        audioPlayer.onended = () => {
            this.setState({ isPlayingAudio: false });
            if(this.props.onPlaybackEnded) {
                this.props.onPlaybackEnded();
            }
        };
        this.setState({ audioPlayer: audioPlayer });
    }

    componentDidUpdate(prev: Props) {
        if(this.props.audioToPlay) {
            if ((prev.audioToPlay !== this.props.audioToPlay)) {
                if(this.state.audioPlayer) {
                    this.state.audioPlayer.src = this.props.audioToPlay;
                    if((prev.showPlayer === this.props.showPlayer) && this.props.showPlayer ) {
                        this.state.audioPlayer.play();
                    }
                }
            }
        }
        else {
            if(this.state.audioPlayer) {
                this.state.audioPlayer.src = "";
            }
        }
    }

    componentWillUnmount() {
        this.state.audioPlayer?.pause();
    }

    render() {
        return (
            <IonFab hidden={!this.props.showPlayer} className='fileViewerAudioPlayer' vertical="bottom" slot='fixed'>
                <IonRow>
                    <IonCol>
                        <IonFabButton
                            onClick={() => {
                                if(this.state.audioPlayer) {
                                    this.state.audioPlayer.pause();       
                                }
                                if(this.props.onBackwardPressed) {
                                    this.props.onBackwardPressed();
                                }
                            }}
                        >
                            <IonIcon icon={playBackOutline} />
                        </IonFabButton>
                    </IonCol>
                    <IonCol>
                        <IonFabButton
                            onClick={() => {
                                if(this.state.audioPlayer) {
                                    if(this.state.isPlayingAudio) {
                                        this.state.audioPlayer.pause();
                                    }
                                    else {
                                        this.state.audioPlayer.play();
                                    }
                                    
                                }
                                if(this.props.onPlayPause) {
                                    this.props.onPlayPause();
                                }
                            }}
                        >
                            <IonIcon icon={this.state.isPlayingAudio ? pauseOutline : playOutline} />
                        </IonFabButton>
                    </IonCol>
                    <IonCol>
                        <IonFabButton
                            onClick={() => {
                                if(this.state.audioPlayer) {
                                    this.state.audioPlayer.pause();       
                                }
                                if(this.props.onForwardPressed) {
                                    this.props.onForwardPressed();
                                }
                            }}
                        >
                            <IonIcon icon={playForwardOutline} />
                        </IonFabButton>
                    </IonCol>
                </IonRow>
            </IonFab>

        );
    }
}

export default TtsPlayer;