import { tutorialsConstants } from './constants';

export interface TutorialsStore  {
    showOnboarding: boolean,
    showFileViewerTutorial: boolean,
}

const initialState: TutorialsStore = {
    showOnboarding: true,
    showFileViewerTutorial: true,
}

const tutorials = (state = initialState, action: any) => {
    switch (action.type) {

        case tutorialsConstants.SHOW_ONBOARDING:
            return {
                ...state,
                showOnboarding: action.show,
            }

        case tutorialsConstants.SHOW_FILE_VIEWER_TUTORIAL:
            return {
                ...state,
                showFileViewerTutorial: action.show,
            }

        default:
            return state

    }
}

export default tutorials;