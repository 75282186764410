import axios from 'axios';

// PRODUCTION ENVIRONMENT
// const apiService = axios.create({ baseURL: "https://api.reasy.estro.ai/rest/" });
// const refreshService = axios.create({ baseURL: "https://api.reasy.estro.ai/rest/" });

// TESTING ENVIRONMENT
const apiService = axios.create({ baseURL: "https://test.api.reasy.estro.ai/rest/" });
export const summarizationApi = axios.create({ baseURL: "https://api.meaningcloud.com/summarization-1.0" })


export const genericApiService = axios.create();


export default apiService;
