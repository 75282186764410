import axios from "axios";

export interface ImageSearchResponse {
    hits: ImageHit[],
}

interface ImageHit {
    id: number,
    webformatURL: string,
    largeImageURL: string,
    previewURL: string,
    tags: string,
}

export function imagesSearch(text: string, language?: "it" | "en") {
    return new Promise<string[]>((resolve, reject) => {
        axios.post(`https://fujjvnytml.execute-api.eu-central-1.amazonaws.com/staging/image-search/`, {
            text: text,
            lang: language ? language : "it"
        })
        .then(response => {
            console.log('[imageSearch] response:', response.data); // for debugging
            resolve(response.data.body);
        })
        .catch(error => {
            console.error('[imageSearch] error returned by axios', error);
            reject(error.response.data);
        });
    })
}
