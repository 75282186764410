import { uploadBytes, ref as storageRef } from '@firebase/storage';
import apiService from '../../../common/apiService';
import { storage } from '../../../firebaseConfig';
import { Drawing, PictureWordMatch } from './reducers';

export const sketchServices = {
    getDrawings,
    getPicture,
    uploadDrawing,
    updatePicture,
    deletePicture,
    getWordPicture,

    getPicturesWordsMatchings,
    matchPictureWithWord,
    deletePictureWordMatching,
};

function getDrawings() {
    return new Promise<Drawing[]>((resolve, reject) => {
        apiService.get(`drawings/`)
            .then(response => {
                //console.log(`[getDrawings] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[getDrawings] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function getPicture(pictureUuid: string) {
    return new Promise<Drawing>((resolve, reject) => {
        apiService.get(`drawings/${pictureUuid}/`)
            .then(response => {
                //console.log(`[getPicture] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[getPicture] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function uploadDrawing(image: File | Blob, fileName?: string) {
    return new Promise<Drawing>((resolve, reject) => {
        let payload = new FormData();

        if (fileName) {
            payload.append('image', image, fileName);
        }
        else {
            payload.append('image', image, `${generateRandomFileName(32)}.svg`);
        }

        const options = { headers: { 'content-type': 'multipart/form-data' } };

        apiService.post(`drawings/`, payload, options)
            .then(response => {
                //console.log(`[uploadDrawing] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[uploadDrawing] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function updatePicture(drawingUrl: string, image: File | Blob, fileName?: string) {
    return new Promise(async (resolve, reject) => {
        uploadBytes(storageRef(storage, drawingUrl), image)
            .then(data => {
                console.log("UPDATED DRAWING ", data);

            })
            .catch(e => {
                console.log('ERROR UPDATING DRAWING', e);

            })
        // Put the new file in the same child ref.
        // Get the new URL
        const url = await storageRef(storage, drawingUrl).fullPath;
        console.log("NEW FULL PATH URL ", url);

        return url;
    })
}

function deletePicture(uuid: string) {
    return new Promise((resolve, reject) => {
        apiService.delete(`drawings/${uuid}/`)
            .then(response => {
                //console.log(`[deletePicture] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[deletePicture] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function getPicturesWordsMatchings() {
    return new Promise<PictureWordMatch[]>((resolve, reject) => {
        apiService.get(`words-pictures/`)
            .then(response => {
                //console.log(`[getPicturesWordsMatchings] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[getPicturesWordsMatchings] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}
function getWordPicture(wordUuid: string) {
    return new Promise<PictureWordMatch[]>((resolve, reject) => {
        apiService.get(`words-pictures/?word__uuid=${wordUuid}`)
            .then(response => {
                //console.log(`[getPicturesWordsMatchings] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[getPicturesWordsMatchings] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function matchPictureWithWord(pictureUuid: string, wordUuid: string) {
    return new Promise<PictureWordMatch>((resolve, reject) => {
        apiService.post(`words-pictures/`, {
            word__uuid: wordUuid,
            picture_uuid: pictureUuid,
        })
            .then(response => {
                //console.log(`[matchPictureWithWord] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[matchPictureWithWord] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function deletePictureWordMatching(pictureUuid: string) {
    return new Promise((resolve, reject) => {
        apiService.delete(`words-pictures/${pictureUuid}/`)
            .then(response => {
                //console.log(`[deletePictureWordMatching] response: ${JSON.stringify(response.data)}`); // FOR DEBUGGING
                resolve(response.data);
            })
            .catch(error => {
                //console.log(`[deletePictureWordMatching] error: ${JSON.stringify(error.response.data)}`);
                reject(error.response.data);
            });
    })
}

function generateRandomFileName(length: number) {
    const randomChars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}