import {
    IonContent, IonButton, IonInput, IonTitle, IonPage,
    IonToolbar, IonHeader, IonGrid, IonAlert, IonToggle, IonButtons, IonIcon, IonBackButton
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import './CredentialsLogin.css';
import './AuthenticationGetStarted.css'
import { auth } from '../../../firebaseConfig';

type Props = {
    history: any,

    isAuthenticated?: boolean,
};

type State = {
    email: string | null,
    password: string | null,

    showLegalTerms: boolean,

    showResetPasswordButton: boolean,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string,
    errorAlertMessage: string,
    showSupportButton: boolean,

};

class CredentialsLogin extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: null,
            password: null,

            showLegalTerms: false,

            showResetPasswordButton: false,

            showErrorAlert: false,
            errorAlertHeaderMessage: "",
            errorAlertMessage: "",
            showSupportButton: false,

        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>
                            Accedi {/* TO BE LOCALIZED */}
                        </IonTitle>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref='/authentication/email-password-authentication' />
                        </IonButtons>
                        {
                            this.state.showSupportButton &&
                            <IonButtons slot='end'>
                                <IonButton
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        window.open('https://wa.me/393661126799');
                                    }}
                                >
                                    {/* <IonIcon
                                        size='large'
                                        icon={supportPersonIcon}
                                    /> */}
                                </IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="getStartedMainGrid">
                        <div className="authGetStartedTextDiv">
                            <p className="authGetStartedWelcomeText">
                                Accedi a Reading Easy {/* TO BE LOCALIZED */}
                            </p>
                        </div>

                        <div className="getStartedInputsDiv">
                            <div>
                                <IonInput
                                    className="getStartedInputField getStartedEmailInputField"
                                    placeholder="La tua email" /* TO BE LOCALIZED */
                                    type="email"
                                    autocomplete="email"
                                    inputMode="email"
                                    value={this.state.email}
                                    onIonChange={(e) => {
                                        this.setState({ email: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <IonInput
                                    className="getStartedInputField"
                                    placeholder="Password" /* TO BE LOCALIZED */
                                    type="password"
                                    inputMode="text"
                                    autocomplete="current-password"
                                    value={this.state.password}
                                    onIonChange={(e) => {
                                        this.setState({ password: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                            </div>
                        </div>

                        <div className="getStartedButtonsDiv">
                            <IonButton
                                className="getStartedButton"
                                onClick={() => {
                                    if (this.state.password === '' || !this.state.password) {
                                        this.setState({
                                            showErrorAlert: true,
                                            errorAlertHeaderMessage: "Password non valida", /* TO BE LOCALIZED */
                                            errorAlertMessage: "Assicurati di aver inserito una password", /* TO BE LOCALIZED */
                                        });
                                        return
                                    }
                                    if (this.state.email === '' || !this.state.email) {
                                        this.setState({
                                            showErrorAlert: true,
                                            errorAlertHeaderMessage: "Email non valida", /* TO BE LOCALIZED */
                                            errorAlertMessage: "Assicurati di aver inserito una email", /* TO BE LOCALIZED */
                                        });
                                        return
                                    }
                                    signInWithEmailAndPassword(auth, this.state.email, this.state.password)
                                    .then(uc => {
                                        // console.log("[GetStarted] user logged. Uid:", uc.user.uid); // TO REMOVE
                                    })
                                    .catch(err => {
                                        //console.log("[GetStarted] error signing-in:", err.code); // TO REMOVE
                                        if (err.code === "auth/user-not-found") {
                                            console.warn("Registrazione dell'utente");
                                            this.setState({ showLegalTerms: true });
                                        }
                                        else if (err.code === "auth/user-disabled") {
                                            this.setState({
                                                showErrorAlert: true,
                                                errorAlertHeaderMessage: "Utente disabilitato", /* TO BE LOCALIZED */
                                                errorAlertMessage: "Contatta il supporto cliccando l'icona in alto a destra.", /* TO BE LOCALIZED */
                                                showSupportButton: true,
                                            });
                                        }
                                        else if (err.code === "auth/wrong-password") {
                                            this.setState({
                                                showErrorAlert: true,
                                                errorAlertHeaderMessage: "Password non valida", /* TO BE LOCALIZED */
                                                errorAlertMessage: "La password è sbagliata. Riprova o resetta la password.", /* TO BE LOCALIZED */
                                                showResetPasswordButton: true,
                                            });
                                        }
                                        else if (err.code === "auth/invalid-email") {
                                            this.setState({
                                                showErrorAlert: true,
                                                errorAlertHeaderMessage: "Email non valida", /* TO BE LOCALIZED */
                                                errorAlertMessage: "Prova a controllare il formato", /* TO BE LOCALIZED */
                                            });
                                        }
                                        else if (err.code === "auth/internal-error") {
                                            this.setState({
                                                showErrorAlert: true,
                                                errorAlertHeaderMessage: "Errore interno", /* TO BE LOCALIZED */
                                                errorAlertMessage: "Contatta il supporto cliccando l'icona in alto a destra.", /* TO BE LOCALIZED */
                                            });
                                        }
                                        else if (err.code === "auth/too-many-requests") {
                                            this.setState({
                                                showErrorAlert: true,
                                                errorAlertHeaderMessage: "Troppe richieste", /* TO BE LOCALIZED */
                                                errorAlertMessage: "Contattare il supporto o ritentare tra un po'.", /* TO BE LOCALIZED */
                                                showResetPasswordButton: true,
                                            });
                                        }
                                    })
                                }}
                            >
                                Accedi {/* TO BE LOCALIZED */}
                            </IonButton>

                            <IonButton
                                className="getStartedResetPasswordButton"
                                fill='clear'
                                onClick={() => {
                                    this.props.history.push(`/authentication/reset-password`);
                                }}
                            >
                                Ho dimenticato la password {/* TO BE LOCALIZED */}
                            </IonButton>
                        </div>
                    </IonGrid>

                    {
                        this.props.isAuthenticated &&
                        <Redirect to="/" />
                    }

                </IonContent>

                <IonAlert
                    isOpen={this.state.showErrorAlert}
                    cssClass='getStartedErrorAlert'
                    onDidDismiss={() => {
                        this.setState({
                            showErrorAlert: false,
                            errorAlertHeaderMessage: "",
                            errorAlertMessage: "",
                        });
                    }}
                    keyboardClose={true}
                    header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
                    message={`<p>${this.state.errorAlertMessage}</p>`} /* TO BE LOCALIZED */
                    buttons={["Ok"]}
                />

            </IonPage>

        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CredentialsLogin);
