import { get, push, ref, remove, update } from "firebase/database";
import { database } from "../../../firebaseConfig";

export interface Highlight {
	uuid: string,
	target: string,
	color: string,
}

export interface SettingsStore {
	highlightings: Highlight[],
	document: DocumentFontSettings,
}

export interface DocumentFontSettings {
	font: string,
	fontSize: number,
	lineLeading: number,
}

export const settingsServices = {
	getHighlightings,
	addHighlighting,
	deleteHighlighting,

	getDocumentViewingSettings,
	editFont,
	editFontSize,
	editLineLeading,

	fromBackendFontNameToCssFontFamily,
};

function getHighlightings(userUuid: string) {
	return new Promise<Highlight[]>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/h`))
		.then(snapshot => {
			const highlightingsArray: Highlight[] = [];
			if(snapshot.exists()) {
				const highlightingKeys = Object.keys(snapshot.val());
				for (let i = 0; i < highlightingKeys.length; i++) {
					highlightingsArray.push({
						uuid: highlightingKeys[i],
						color: snapshot.val()[highlightingKeys[i]].c as string,
						target: snapshot.val()[highlightingKeys[i]].t as string,
					});
				}
			}
			resolve(highlightingsArray);
		})
		.catch(err => {
			console.error("[Settings services getHighlightings] error:", err);
			reject(err);
		});
	})
}

function addHighlighting(userUuid: string, target: string, color: string) {
	return new Promise<Highlight>((resolve, reject) => {
		push(ref(database, `a/${userUuid}/h`), {
			c: color,
			t: target,
		})
		.then(dbRef => {
			if(dbRef.key) {
				resolve({
					uuid: dbRef.key,
					color: color,
					target: target.trim(),
				})
			}
			else {
				reject("Db ref key is null" + dbRef);
			}
		})
		.catch(err => {
			console.error("[Settings services] error pushing highlighting:", err);
			reject(err);
		})
	})
}

function deleteHighlighting(userUuid: string, highlightingUuid: string) {
	return new Promise((resolve, reject) => {
		remove(ref(database, `a/${userUuid}/h/${highlightingUuid}`))
		.then(response => {
			resolve(response);
		})
		.catch(err => {
			console.error('[Setting services] deleteHighlighting error:', err);
			reject(err);
		});
	})
}

function getDocumentViewingSettings(userUuid: string) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		get(ref(database, `a/${userUuid}/f`))
		.then(snapshot => {
			if(snapshot.exists()) {
				resolve({
					font: snapshot.val().f ? snapshot.val().f : "arial",
					lineLeading: snapshot.val().l ? snapshot.val().l : 0,
					fontSize: snapshot.val().s ? snapshot.val().s : 30,
				});
			}
			else {
				resolve({
					font: "arial",
					lineLeading: 50,
					fontSize: 30,
				});
			}
			//console.log('[getDocumentViewingSettings] response:', response.data); // for debugging

		})
		.catch(err => {
			console.error('[getDocumentViewingSettings] error:', err);
			reject(err);
		});
	})
}

function editFontSize(userUuid: string, fontSize: number) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			s: fontSize,
		})
		.then(() => {
			getDocumentViewingSettings(userUuid)
			.then(documentFontSettings => {
				resolve(documentFontSettings);
			})
			.catch(err => {
				reject(err);
			})
		})
		.catch(err => {
			console.error('[getDocumentViewingSettings] error:', err);
			reject(err);
		});
	})
}

function editLineLeading(userUuid: string, lineLeading: number) {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			l: lineLeading,
		})
		.then(() => {
			getDocumentViewingSettings(userUuid)
			.then(documentFontSettings => {
				resolve(documentFontSettings);
			})
			.catch(err => {
				reject(err);
			})
		})
		.catch(err => {
			console.error('[getDocumentViewingSettings] error:', err);
			reject(err);
		});
	})
}

function editFont(userUuid: string, font: "arial" | 'tahoma' | 'serif' | 'sans_serif' | "easy_reading" | "verdana") {
	return new Promise<DocumentFontSettings>((resolve, reject) => {
		update(ref(database, `a/${userUuid}/f`), {
			f: font,
		})
		.then(() => {
			getDocumentViewingSettings(userUuid)
			.then(documentFontSettings => {
				resolve(documentFontSettings);
			})
			.catch(err => {
				reject(err);
			})
		})
		.catch(err => {
			console.error('[getDocumentViewingSettings] error:', err);
			reject(err);
		});
	})
}

export function fromBackendFontNameToCssFontFamily(fontName: string) {
	switch (fontName) {
		case 'arial':
			return 'Arial, Helvetica, sans-serif';

		case 'georgia':
			return `Georgia, Times, Times New Roman, serif`;

		case 'tahoma':
			return `Tahoma, Verdana, Segoe, sans-serif`;

		case 'verdana':
			return `Verdana, Geneva, sans-serif`;

		case 'easy_reading':
			return `Easy Reading, Arial, sans-serif`;

		default:
			return 'Arial, Helvetica, sans-serif';
	}
}