import {
	IonButton, IonContent, IonGrid, IonIcon, IonImg, IonPage,
	IonRow
} from '@ionic/react';
import { sparkles } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import check from '../../../assets/illustrations/check.json';
import './AuthenticationGetStarted.css';
import reasyLogo from '../../../assets/icons/reasy_full_logo.svg';
import euLogo from '../../../assets/images/eulogo.jpg'

type Props = {
	history: any,
	isAuthenticated: boolean,
}

type State = {
	policyChecked: boolean,
	email: string | null | undefined,
	isValidEmail: boolean,
	showConfirmationLabel: boolean
}

class AuthenticationGetStarted extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			policyChecked: false,
			email: '',
			isValidEmail: false,
			showConfirmationLabel: false,
		}
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: check,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice"
			}
		};

		return (
			<IonPage>

				<IonContent fullscreen>

					<IonGrid className="authGetStartedMainGrid">
						<div className="authGetStartedTextDiv">
							<p className="authGetStartedWelcomeText">
								Ti diamo il benvenuto su {/* TO BE LOCALIZED */} {/* TO EDIT */}
							</p>
						</div>

						<div className="authGetStartedIllustrationDiv">
							<h1>
								Reading easy
							</h1>
						</div>

						<div className="authGetStartedButtonsDiv">
							<IonButton
								onClick={() => this.props.history.push('/authentication/email-password-authentication')}
								className="authGetStartedEmailAndPasswordButton"
								fill="outline"
								color="medium"
							>
								Email e password {/* TO BE LOCALIZED */}
							</IonButton>


							<IonButton
								className="authGetStartedMagicLinkButton"
								routerLink="/authentication/magic-link-request"
							>
								<IonIcon
									className="authGetStartedMagicLinkButtonIcon"
									icon={sparkles}
								/>
								Accedi con link magico {/* TO BE LOCALIZED */}
							</IonButton>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<IonImg src={euLogo} style={{ width: '200px' }} />
							<p style={{ fontSize: '8px ' }}>
								VRAIlexia - Partnering Outside the Box: Digital and Artificial Intelligence Integrated Tools to Support Higher Education Students with Dyslexia.Erasmus+ Programme2014-2020 – Key Action 2: Strategic Partnership Projects<br />AGREEMENT n. 2020-1-IT02-KA203-080006
							</p>
						</div>
					</IonGrid>

					{this.props.isAuthenticated && <Redirect to="/" />}
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.access ? true : false,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationGetStarted);