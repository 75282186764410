import { SettingsTranslationsEN } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsEN } from '../../modules/user/ProfileTranslations'
import { MenuTranslationsEN } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsEN } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsEN } from '../../modules/files/FileTranslations';
import { PopoverTranslationsEN } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsEN } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsEN } from '../../modules/onboarding/OnboardingTranslations';

const mergedObject = {
  ...SettingsTranslationsEN,
  ...ProfileTranslationsEN,
  ...MenuTranslationsEN,
  ...SketchTranslationsEN,
  ...FileTranslationsEN,
  ...PopoverTranslationsEN,
  ...AuthTranslationsEN,
  ...OnboardingTranslationsEN,
  };

export const en = {
  mergedObject,
}