import { IonButton, IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import Dictionary from './components/Dictionary';
import WordActions from './components/WordActions';
import "./SelectionPopover.css";


type Props = {
    history: any
    onDidDismiss?: () => void,
}

type State = {
    navigationStack: string[],

    selectedText: string | null,
    selection: Selection | null,

    scrollOffsetY: number,
};


class SelectionPopover extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            navigationStack: [],

            selectedText: null,
            selection: null,

            scrollOffsetY: 0,
        }
    }


    changeSelectedText(text: string | null) {
        this.setState({ selectedText: text });
        if (!text) {
            this.setState({ navigationStack: [] });
        }
    }

    showPopover(show: boolean) {
        if (this.state.selectedText && this.state.selection && show) {
            this.calculateAndSetPopoverPosition();
        }
        else if (!show) {
            this.dismissPopover()
        }
    }

    setSelection(selection: Selection | null) {
        this.setState({ selection: selection });
    }

    setScrollOffsetY(offsetY: number) {
        this.setState({ scrollOffsetY: offsetY });
    }

    navigateTo(path: string) {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.push(path);
        this.setState({
            navigationStack: navStackCopy
        });
    }

    navigateBack() {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.pop();
        this.setState({
            navigationStack: navStackCopy,
        });
    }

    clearNavigationStack() {
        this.setState({
            navigationStack: [],
        });
    }

    componentDidUpdate() {
        // console.log("[SelectionPopover] component did update"); // TO REMOVE
        /* let _this = this; */
        /* window.addEventListener("orientationchange", function () {
            _this.dismissPopover()
        }, false); */

        if (this.state.selectedText && this.state.selection) {
            //this.calculateAndSetPopoverPosition();
        }
        else {
            this.dismissPopover();
        }

    }

    calculateAndSetPopoverPosition() {
        //console.log('FRASE CLICCATA ', this.props.selectedSentence)

        //console.log('[SelectionPopover] calculatePopoverPosition width:', window.innerWidth, 'height:', window.innerHeight)
        const selectionPopoverOuterDiv: HTMLDivElement = document.getElementById('selectionPopoverOuterDiv') as HTMLDivElement;
        const selectionPopoverInnerDiv: HTMLDivElement = document.getElementById('selectionPopoverInnerDiv') as HTMLDivElement;

        let popoverWidth = 400;
        if (window.innerWidth * 0.8 < 400) {
            popoverWidth = Math.round(window.innerWidth * 0.8);
        }

        const sentenceBoundingRect = this.state.selection?.getRangeAt(0).getBoundingClientRect();

        //console.log('[SelectionPopover] Sentence component x:', sentenceBoundingRect)
        if (sentenceBoundingRect) {
            if ((sentenceBoundingRect.x + popoverWidth) > window.innerWidth) {
                if (popoverWidth < 400) {
                    // client piccolo
                }
                else {
                    selectionPopoverInnerDiv.style.removeProperty('left');
                    selectionPopoverInnerDiv.style.removeProperty('margin-top');
                    selectionPopoverInnerDiv.style.removeProperty('margin-bottom');

                    selectionPopoverInnerDiv.style.position = `relative`;
                    selectionPopoverInnerDiv.style.marginLeft = sentenceBoundingRect.x - popoverWidth + sentenceBoundingRect.width + 'px';
                    selectionPopoverInnerDiv.style.marginRight = `0`;
                }

            }
            else if (sentenceBoundingRect.x + (sentenceBoundingRect.width / 2) - (popoverWidth / 2) < 0) {
                //console.log('starting x:', sentenceBoundingRect.x + (sentenceBoundingRect.width / 2) - (popoverWidth / 2))
                selectionPopoverInnerDiv.style.position = `relative`;
                selectionPopoverInnerDiv.style.marginLeft = `0`;
                selectionPopoverInnerDiv.style.marginRight = `auto`;
            }
            else {
                selectionPopoverInnerDiv.style.margin = `unset`;
                selectionPopoverInnerDiv.style.position = `relative`;
                const selectionPopoverInnerDivPosX = Math.round(sentenceBoundingRect.x + (sentenceBoundingRect.width / 2) - (popoverWidth / 2));
                //console.log('[Selection popover] inner div pos x:', selectionPopoverInnerDivPosX);
                selectionPopoverInnerDiv.style.left = `${selectionPopoverInnerDivPosX}px`;
            }

            if ((sentenceBoundingRect.y + selectionPopoverOuterDiv.getBoundingClientRect().height) > window.innerHeight) {
                selectionPopoverOuterDiv.style.top = `${this.state.scrollOffsetY + sentenceBoundingRect.y - selectionPopoverOuterDiv.getBoundingClientRect().height - 30}px`
                selectionPopoverOuterDiv.style.visibility = 'visible';
            }
            else {
                selectionPopoverOuterDiv.style.top = `${this.state.scrollOffsetY + sentenceBoundingRect.top + 50}px`;
                selectionPopoverOuterDiv.style.visibility = 'visible';
            }
        }
    }

    dismissPopover() {
        // console.log("[SelectionPopover] dismiss");
        if (!window.location.pathname.includes('/file')) {
            return;
        }
        else {
            const selectionPopoverOuterDiv: HTMLDivElement = document.getElementById('selectionPopoverOuterDiv') as HTMLDivElement;
            if (selectionPopoverOuterDiv) {
                selectionPopoverOuterDiv.style.visibility = 'hidden';
            }
        }

    }

    render() {
        return (
            <div
                id='selectionPopoverOuterDiv'
                className='selectionPopoverOuterDiv'
            >
                <div
                    id='selectionPopoverInnerDiv'
                    className='selectionPopoverInnerDiv'
                >
                    {
                        this.state.navigationStack.length > 0 &&
                        <IonButton
                            className='selectionPopoverNavigateBackDiv'
                            fill="clear"
                            onClick={() => {
                                this.navigateBack();
                            }}
                        >
                            <IonIcon
                                className='selectionPopoverNavigateBackIcon'
                                icon={chevronBack}
                            />
                        </IonButton>
                    }

                    {
                        this.state.navigationStack.length === 0 &&
                        <WordActions
                            history={this.props.history}
                            selectedText={this.state.selectedText}
                            onDictionaryClicked={() => {
                                this.navigateTo('wordDictionary');
                            }}
                        />
                    }

                    {
                        (this.state.navigationStack.length > 0) &&
                        this.state.navigationStack[this.state.navigationStack.length - 1] === "wordDictionary" &&
                        <Dictionary
                            text={this.state.selectedText}
                        />
                    }

                </div>
            </div>
        );
    }
}

export default SelectionPopover;                   
