import React from 'react';
import {
    IonContent, IonPage, IonGrid, IonSlides, IonImg,
}
from '@ionic/react';
import { connect } from 'react-redux';
import OnboardingUploadFile from './OnboardingUploadFile';
import OnboardingReasy from './OnboardingReasy';
import OnboardingSettings from './OnboardingSettings';
import { Trans } from 'react-i18next';
import unitusLogo from '../../../assets/images/unitusLogo.png';
import './Onboarding.css';

type Props = {
	history: any,
}

type State = {
}

class Onboarding extends React.Component<Props, State> {

	render() {
		return (
			<IonPage>
				<IonContent fullscreen>
					<IonGrid className='onboardingGrid'>
						<h1 className='onboardingHeader'>
							<Trans>Ti diamo il benvenuto su Reasy</Trans>&nbsp;
						</h1>
						<IonSlides
							id='onboardingSlides'
							pager={true}
						>
							<OnboardingReasy />
							
							<OnboardingUploadFile />

							<OnboardingSettings
								history={this.props.history}
							/>
						</IonSlides>
					</IonGrid>
				</IonContent>
				<div className='onboardingLogosDiv'>
					<IonImg
						className='onboardingUnitusLogoImg'
						src={unitusLogo}
					/>
				</div>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);