import React from 'react';
import {
    IonPage, IonContent, IonGrid, IonHeader, IonButtons, IonBackButton, IonToolbar, IonTitle, IonButton,
} from '@ionic/react';
import { connect } from 'react-redux';
import './PrivacyPolicy.css';

type Props = {
    history: any,
};

type State = {
};

class PrivacyPolicy extends React.Component<Props, State> {

    /* constructor(props: any) {
        super(props);
        this.state = {
        }
    }
    */
    // NB: TUTTI I RIFERIMENTI A 'ESTRO' ALL'INTERNO DEL TESTO SONO STATI SOSTITUITI CON 'Reasy'
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref='/' />
                        </IonButtons>
                        <IonTitle>Informativa sulla privacy</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid className='privacyPolicyGrid'>
                        <h1>Privacy policy</h1>
                        <p>
                            Questa Privacy Policy descrive le pratiche sulla privacy della Piattaforma
                            e delle sue affiliate (“noi” e/o “nostro”) in relazione al nostro sito web
                            e alle nostre app (i “Servizi”). Questa Privacy Policy illustra i tipi di informazioni
                            personali che raccogliamo, come possiamo usare e condividere le informazioni e le scelte
                            di privacy che l’utente ha in relazione all’utilizzo delle informazioni personali sull’utente.
                        </p>
                        <h3>
                            Come raccogliamo informazioni
                        </h3>
                        <p>
                            Raccogliamo informazioni in vari modi, tra cui:
                        </p>

                        <ul>
                            <li>Direttamente dall’utente, sotto la sua direzione o con il consenso dell’utente</li>
                            <li>Relativamente all’uso dell’utente dei servizi (per esempio attraverso le applicazioni
                                o i dispositivi l’utente connette ai servizi)</li>
                            <li>Dai nostri venditori, partner e altre fonti, come i dati online e offline</li>
                            <li>Da altre piattaforme che l’utente potrebbe usare per interagire con noi</li>
                            <li>Automaticamente, dai dispositivi che l’utente usa per connettersi al nostro sito o app.</li>
                        </ul>
                        <br />
                        <h4>Tipi di informazioni che raccogliamo</h4>
                        <p>I tipi di informazione che raccogliamo includono:</p>

                        <ul>
                            <li>
                                Informazioni ottenute dall’utente, come indirizzo email, indirizzo postale,
                                numero di telefono e informazioni demografiche, come la data di nascita, genere,
                                codice di avviamento postale o zip code. Se l’utente crea un account, potremmo raccogliere informazioni
                                aggiuntive, come l’immagine del profilo e ogni informazione l’utente sceglie di fornire, comprese informazioni
                                relativi alla salute e appunti di sessione.
                            </li>
                            <li>
                                Informazioni fornite da membri del team e dai caregiver,
                                come informazioni riguardo alla salute del figlio dell’utente, informazioni diagnostiche e altre informazioni
                                relative allo stato di salute. Quando l’utente ci fornisce informazioni personali relative ad altre persone,
                                dichiara di avere l’autorità per farlo e quindi per permetterci di usare le informazioni in relazione
                                alla Privacy Policy.
                            </li>
                            <li>
                                Informazioni riguardo al team, come il numero di familiari, insegnanti,
                                caregiver e altre persone che si occupano del soggetto.
                            </li>
                            <li>
                                Informazioni che raccogliamo automaticamente, come dettagli riguardo applicazioni e dispositivi usati
                                per accedere ai nostri servizi (come indirizzo IP, informazioni del browser, informazioni sul dispositivo
                                e sul sistema operativo). Potremmo anche raccogliere alcune informazioni riguardo all’utilizzo del nostro sito web,
                                delle app per dispositivi mobili, alla presenza sui social media con mezzi automatizzati, come cookie, web beacon
                                e altre tecnologie. Potremmo anche raccogliere informazioni sulle attività online nel tempo e sui siti web di terzi.
                            </li>
                        </ul>
                        <p>
                            Di volta in volta, potremmo usare o integrare le informazioni che abbiamo sull’utente
                            con informazioni ottenute da altre fonti, come database pubblici, social media e da terzi.
                            Per esempio, potremmo usare le informazioni di terzi per confermare le informazioni contrattuali
                            o per capire meglio gli interessi dell’utente, associando alle informazioni da lui prodotte informazioni demografiche.
                        </p>
                        <h4>
                            Cosa accade se l’utente non ci fornisce le proprie informazioni personali?
                        </h4>
                        <p>
                            Se l’utente non fornisce le informazioni che gli sono state richieste relativamente ai nostri servizi
                            o alle nostre funzioni di business e attività, potremmo non essere in grado di fornire all’utente il servizio,
                            rispondere a lamentele o di fornirgli supporto. Ad esempio, potremmo non essere in grado di fornirgli
                            informazioni riguardo ai prodotti e servizi che potrebbe desiderare.
                        </p>
                        <h4>
                            Come usiamo le informazioni
                        </h4>
                        <p>
                            Possiamo utilizzare le informazioni che raccogliamo per gestire i nostri Servizi.
                            Inoltre raccogliamo, manteniamo, divulghiamo e utilizziamo le informazioni per:
                        </p>
                        <ul>
                            <li>Comunicare con l’utente riguardo ai nostri servizi</li>
                            <li>Facilitare l’interazione con i servizi, permettere all’utente di postare commenti, creare note e report di sessione, tracciare le tappe;</li>
                            <li>Connettere l’utente con altri caregiver e il team;</li>
                            <li>Analizzare e accrescere le nostre comunicazioni e strategie (ad esempio sapendo quando le email, messaggi e notifiche vengono ricevute e lette);</li>
                            <li>Condurre sondaggi e svolgere altre analisi di mercato;</li>
                            <li>Rispondere a richieste e commenti;</li>
                            <li>Indirizzare all’utente newsletter, articoli, annunci, inviti e altre informazioni riguardo a prodotti e argomenti riguardanti la salute;</li>
                            <li>Se l’utente dà il proprio consenso, gli invieremo e-mail, messaggi, notifiche o telefonate, gli invieremo comunicazioni relative a caratteristiche o servizi, così come le prossime sperimentazioni cliniche, la ricerca, l’educazione al paziente o altri servizi rilevanti per l’utente;</li>
                            <li>Consentire all’utente di connettersi con terze parti o utilizzare funzionalità di condivisione di terze parti come widget/strumenti/pulsanti di social media di terze parti;</li>
                            <li>permettere all’utente di accedere o usare alcuni servizi o contenuti all’interno delle applicazioni (ad esempio alcune caratteristiche del servizio potrebbero essere disponibili solo per il pediatra o il medico di famiglia);</li>
                            <li>Rispettare i requisiti legali, i procedimenti giudiziari e le nostre politiche (incluso verificare l’identità degli utenti in relazione alle richieste di accesso o di correzione).</li>
                        </ul>
                        <p>
                            Potremmo anche aggiungere qualsiasi informazione personale che raccogliamo
                            in relazione ai nostri Servizi, in modo che le informazioni non siano più identificabili
                            personalmente o attribuibili all’utente. Possiamo utilizzare tali informazioni per i nostri legittimi
                            scopi commerciali senza restrizioni.
                        </p>
                        <h4>
                            Basi legali per l’elaborazione delle informazioni
                        </h4>
                        <p>
                            Di seguito sono riportati i motivi legali su cui ci basiamo per elaborare le informazioni personali
                        </p>
                        <ul>
                            <li>Interesse legittimo. Reasy ha un legittimo interesse nel processare le informazioni personali che raccogliamo, per sviluppare, amministrare e supportare i Servizi; per operare, valutare e migliorare la nostra attività; per facilitare l’utilizzo dei Servizi e altri programmi di sostegno e coinvolgimento; promuovere la ricerca accademica; per sostenere le nostre attività di sviluppo dell’apprendimento; o per facilitare una vendita di beni o fusioni o acquisizioni.</li>
                            <li>Accertare, esercitare o difendersi da frodi, attività illegali e reclami e altre responsabilità;</li>
                            <li>Rispettare i nostri obblighi legali;</li>
                            <li>Il consenso dell’utente: contando sul consenso dell’utente per il trattamento dei suoi dati personali, cercheremo tale consenso prima di raccogliere le sue informazioni personali. Per informazioni su come revocare il consenso, l’utente è invitato a consultare la sezione “I diritti e le scelte dell’utente” di seguito.</li>
                        </ul>
                        <h4>
                            Come condividiamo le informazioni dell’utente
                        </h4>
                        <p>
                            Potremmo condividere le informazioni che raccogliamo:
                        </p>
                        <ul>
                            <li>All’interno della nostra compagnia e con nostri affiliati</li>
                            <li>Con i nostri fornitori di servizi che eseguono servizi per noi a nostro nome, anche ai fini dell’utilizzo del nostro sito Web e delle nostre app, aiutandoci a svolgere le funzioni e le operazioni aziendali e ad adempiere alle richieste da parte dell’utente. Tali fornitori di servizi potrebbero includere provider di hosting web, fornitori di hosting di app, amministratori di sistemi IT, gestori di emailing, corrieri, processori di pagamento, fornitori di servizi di inserimento dati, amministratori di reti elettroniche e consulenti professionali come commercialisti, avvocati, consulenti aziendali e altri consulenti;</li>
                            <li>Con partner selezionati con cui collaboriamo;</li>
                            <li>Con il consenso dell’utente e dietro sua indicazione;</li>
                            <li>Quando l’utente volontariamente fornisce dei contenuti, quei contenuti saranno condivisi con altri utenti. L’utente è invitato a condividere solo contenuti che non gli creino problemi nella condivisione;</li>
                        </ul>
                        <p>
                            Potremmo anche divulgare informazioni personali per ottemperare a un obbligo legale,
                            proteggere e difendere i nostri diritti o la nostra proprietà, proteggere la sicurezza
                            dei nostri utenti o del pubblico, o per proteggerci dalla responsabilità legale.
                        </p>
                        <p>
                            Ci riserviamo il diritto di trasferire le informazioni che manteniamo nel caso
                            in cui vendessimo o trasferissimo l’intera attività o una parte di essa e dei nostri beni.
                            Se ci impegniamo in tale vendita o trasferimento, faremo sforzi ragionevoli per indirizzare
                            il destinatario verso l’utilizzo delle informazioni personali in modo coerente con la presente
                            Informativa sulla privacy. Dopo tale vendita o trasferimento, l’utente potrà contattare il destinatario
                            per qualsiasi domanda riguardante le pratiche sulla privacy del destinatario.
                        </p>
                        <h4>
                            Minori
                        </h4>
                        <p>
                            La nostra piattaforma è riservata ai maggiori di 18 anni.
                            Se l’utente ha meno di 18 anni, non è autorizzato a utilizzare la nostra piattaforma.
                            La condizione in base alla quale elaboriamo le informazioni di coloro la cui età è inferiore
                            ai 18 anni è il consenso dell’utente a nome del soggetto minorenne. Utilizzeremo solo le informazioni
                            fornite dall’utente per fornire i servizi richiesti dall’utente.
                        </p>
                        <h4>
                            I diritti e le scelte dell’utente
                        </h4>
                        <p>
                            Ci riserviamo il diritto di verificare l’identità dell’utente relativamente
                            ad ogni richiesta riguardante informazioni personali per assicurarci di fornire
                            le informazioni che possediamo solamente agli individui di cui queste informazioni
                            sono pertinenza e per consentire solo a tali individui o ai loro rappresentanti
                            autorizzati di esercitare i diritti in relazione a tali informazioni.
                        </p>
                        <p>
                            Accesso e correzione delle informazioni personali dell’utente:
                            l’utente potrà accedere alle informazioni che manteniamo su di lui inviandoci
                            una richiesta utilizzando i dettagli di contatto di seguito. Se acconsentiamo alla
                            vostra richiesta, forniremo all’utente una copia delle informazioni personali che manteniamo
                            su di lui nel normale corso dell’attività, in un formato comunemente utilizzato. L’utente può
                            richiedere l’accesso per correggere eventuali errori nelle sue informazioni personali. Potremmo
                            rifiutare la richiesta di accesso o di rettifica delle informazioni personali, come consentito
                            dalla legge applicabile. Se rifiutiamo la richiesta, comunicheremo all’utente i motivi del rifiuto.
                        </p>
                        <p>
                            Tutti gli individui hanno il diritto di accedere e correggere le loro informazioni
                            personali come descritto sopra. Tuttavia, gli individui in determinate giurisdizioni,
                            come nell’Unione Europea, possono avere determinati diritti e scelte aggiuntive riguardo al
                            nostro trattamento delle informazioni personali. Questi diritti e scelte aggiuntive sono:
                        </p>
                        <ul>
                            <li>Portabilità delle informazioni personali: In determinate condizioni, l’utente può richiedere che trasferiamo le sue informazioni personali a un’altra entità nel formato in cui le manteniamo nel normale corso dell’attività. Potremmo rifiutare la richiesta di accesso o di rettifica delle informazioni personali, come consentito dalla legge applicabile. Se rifiutiamo la richiesta, comunicheremo all’utente i motivi del rifiuto.</li>
                            <li>Cancellazione delle informazioni personali: l’utente può richiedere l’eliminazione delle informazioni personali. Possiamo respingere la tua richiesta, come consentito dalla legge applicabile. Ad esempio, motivi legali potrebbero richiedere a Reasy di conservare le informazioni personali nei propri documenti aziendali. Se rifiutiamo la richiesta, comunicheremo all’utente i motivi del rifiuto</li>
                            <li>Obiezione al trattamento di dati personali. L’utente può avere il diritto di opporsi al nostro trattamento dei dati relativi all’utente. Se rifiutiamo la richiesta, comunicheremo all’utente i motivi del rifiuto.</li>
                            <li>Ritiro del consenso: se Reasy conta sul consenso dell’utente per il trattamento dei suoi dati personali, cercheremo tale consenso prima di raccogliere le sue informazioni personali. Nella misura prevista dalla legge applicabile, l’utente può ritirare qualsiasi consenso precedentemente fornitoci o opporsi in qualsiasi momento, per motivi legittimi, al trattamento dei dati personali dell’utente. Applicheremo queste preferenze da quel momento in poi. In alcune circostanze, il ritiro del consenso al nostro uso o alla divulgazione delle informazioni personali implica il fatto che Reasy possa non essere più in grado di fornire all’utente servizi o informazioni sulla programmazione o sulle iniziative che potrebbero interessarlo.</li>
                        </ul>
                        <p>
                            L’utente è invitato a contattarci usando i dettagli di contatto qui sotto se
                            l’utente desidera esercitare uno di questi diritti o richiedere maggiori informazioni.
                        </p>
                        <p>
                            Si noti che i residenti dello Spazio economico europeo hanno anche il diritto
                            di presentare un reclamo all’autorità di vigilanza del loro stato membro.
                        </p>
                        <h4>
                            Marketing
                        </h4>
                        <p>
                            L’utente può disiscriversi dalla ricezione di email commerciali o di marketing da parte nostra
                            seguendo le istruzioni contenute nella email. Tuttavia, anche se l’utente decide di non ricevere
                            più queste comunicazioni, manteniamo il diritto di inviare comunicazioni non commerciali
                            (come informazioni riguardo alle modifiche delle Privacy Policy o delle Condizioni di Utilizzo).
                        </p>
                        <h4>
                            Cookies e pubblicità
                        </h4>
                        <p>
                            Noi, i nostri fornitori di servizi e i nostri partner commerciali
                            potremmo anche raccogliere determinate informazioni sull’utilizzo dei nostri
                            siti Web e app con mezzi automatizzati, come cookie, web beacon e altre tecnologie.
                            Potremmo anche raccogliere informazioni sulle attività online e sui siti Web di terzi.
                        </p>
                        <p>
                            Le informazioni che possono essere raccolte con mezzi automatizzati includono:
                        </p>
                        <ul>
                            <li>URL che rimandano gli utenti al nostro sito web;</li>
                            <li>Termini di ricerca utilizzati per raggiungere il nostro sito Web;</li>
                            <li>Dettagli sui dispositivi utilizzati per accedere al nostro sito Web (come indirizzo IP, tipo di browser e informazioni sul sistema operativo);</li>
                            <li>Dettagli sull’interazione degli utenti con il nostro sito Web (come la data, l’ora, la frequenza e la durata delle visite e le pagine specifiche cui si accede durante le visite).</li>
                        </ul>
                        <p>
                            I browser Web possono offrire agli utenti del nostro sito Web la possibilità
                            di disabilitare la ricezione di determinati tipi di cookie; tuttavia, se i cookie sono disabilitati,
                            alcune caratteristiche o funzionalità del nostro sito Web potrebbero non funzionare correttamente.
                            Per supportare e migliorare i servizi, possiamo consentire a terzi di fare pubblicità, attraverso i servizi.
                            Queste pubblicità sono a volte mirate a determinati utenti e possono provenire da società di terze parti
                            chiamate “reti pubblicitarie”. Le reti pubblicitarie comprendono ad esempio server di terze parti, agenzie
                            pubblicitarie, fornitori di tecnologie pubblicitarie e società di ricerca. Non siamo responsabili per
                            le pratiche sulla privacy di queste reti pubblicitarie e di altre parti. Gli annunci pubblicati attraverso
                            i servizi possono essere indirizzati agli utenti che rientrano in una determinata categoria di profilo
                            generale, che può essere dedotta dalle informazioni fornite dall’utente, in base ai modelli di utilizzo
                            dei servizi o in base all’attività dell’utente sui Servizi di terzi (come definito in questa Privacy Policy).
                            Non forniamo informazioni personali a reti pubblicitarie per uso diverso da quello relativo ai servizi.
                            <a href="https://www.networkadvertising.org/understanding-online-advertising">&nbsp;
                                Per saperne di più sulle reti pubblicitarie e per regolare le tue preferenze, clicca qui.
                            </a>
                        </p>
                        <p>
                            Poiché non c’è ancora un consenso su come le aziende dovrebbero rispondere al browser
                            web con meccanismo do not track (“DNT”), non rispondiamo ai segnali DNT basati sul browser in questo momento.
                        </p>
                        <h4>
                            Link ad altri siti web e contenuti di terze parti
                        </h4>
                        <p>
                            Possiamo fornire collegamenti ad altri siti Web, servizi e applicazioni che non sono gestiti
                            o controllati da noi (i “Servizi di terzi”). Questa Privacy Policy non si applica ai Servizi
                            di terze parti. Anche se tentiamo di facilitare l’accesso solo a quei Servizi di terze parti
                            che condividono il nostro rispetto per la privacy dell’utente, non possiamo assumerci la responsabilità
                            per il contenuto, le politiche sulla privacy o le pratiche di tali Servizi di terzi. Invitiamo l’utente
                            a esaminare e comprendere le pratiche sulla privacy di qualsiasi servizio di terzi prima di fornire qualsiasi
                            informazione a oppure tramite loro. Le interazioni dell’utente con queste funzionalità sono regolate dall’informativa
                            sulla privacy del Servizio di terze parti che fornisce la funzionalità.
                        </p>
                        <h4>
                            International Data Transfers
                        </h4>
                        <p>
                            Possiamo trasferire le informazioni personali dell’utente in paesi
                            diversi dal paese in cui inizialmente l’utente ha fornito le informazioni
                            per gli scopi descritti nella presente Privacy Policy. I paesi verso i quali
                            trasferiamo le informazioni personali potrebbero non garantire lo stesso
                            livello di protezione delle informazioni personali di quello in cui l’utente risiede.
                        </p>
                        <p>
                            Possiamo trasferire informazioni personali dall’Area Economica Europea (“EEA”)
                            a paesi che la Commissione Europea ha ritenuto in grado di salvaguardare
                            adeguatamente le informazioni personali. In questo caso non sono richieste
                            ulteriori garanzie per trasferire le informazioni. Se trasferiamo le informazioni personali
                            dell’utente ad altri Paesi, lo facciamo in base alla conformità del destinatario alle clausole
                            contrattuali standard, allo scudo UE-USA Privacy o alle Regole aziendali vincolanti,
                            o con il consenso dell’utente al trasferimento, a meno che non sia consentito dalla
                            legge trasferire informazioni personali senza tali formalità.
                        </p>
                        <h4>
                            Come proteggiamo le informazioni
                        </h4>
                        <p>
                            Manteniamo tutele amministrative, tecniche e fisiche progettate per proteggere le informazioni
                            personali che conserviamo da distruzione, perdita, alterazione, accessi non autorizzati, divulgazione
                            o utilizzo accidentali ed illeciti.
                        </p>
                        <p>
                            Nonostante tali sforzi, tuttavia, si ricorda che nessuna organizzazione può
                            eliminare completamente i rischi o garantire la sicurezza delle informazioni personali.
                            L’accesso o l’uso non autorizzato, l’errore hardware o software e altri fattori possono
                            compromettere la sicurezza delle informazioni riguardanti l’utente in qualsiasi momento
                            e non ci assumiamo alcuna responsabilità per l’uso o la divulgazione di informazioni personali
                            o di altri dati derivanti dal furto di informazioni o altre azioni dannose.
                        </p>
                        <h4>
                            Contenuto inserito dall’utente
                        </h4>
                        <p>
                            Alcune caratteristiche del servizio permettono all’utente di postare contenuto,
                            come fotografie, sul nostro sito web, sull’applicazione o sulle pagine social media.
                            Il contenuto che l’utente fornisce potrebbe essere condiviso pubblicamente o con altri utenti di terzi.
                        </p>
                        <h4>
                            Conservazione dei dati
                        </h4>
                        <p>
                            Conserviamo i dati personali per tutto il tempo necessario per gli scopi di elaborazione per
                            i quali sono stati raccolti i dati e per qualsiasi altro scopo correlato ammissibile.
                            Quando non abbiamo più bisogno delle informazioni personali che raccogliamo, anonimizziamo
                            le informazioni o le distruggiamo in modo sicuro.
                        </p>
                        <h4>
                            Modifiche alla nostra Privacy Policy
                        </h4>
                        <p>
                            Possiamo cambiare, in qualsiasi momento, la nostra politica sulla privacy.
                            In caso di modifiche, pubblicheremo tali modifiche su questa pagina e nella parte
                            superiore di questa pagina verrà indicata la data in cui è stata effettuata l’ultima modifica
                            alla Privacy Policy. L’utente può leggere una copia aggiornata della presente
                            Privacy Policy in qualsiasi momento visitando questa pagina. È necessario
                            rivedere la presente Privacy Policy di volta in volta per accertarsi di
                            rimanere aggiornati nel caso in cui vengano apportate modifiche alla presente Privacy Policy.
                        </p>
                        <h4>
                            Contattaci
                        </h4>
                        <p>
                            In caso di domande, richieste o reclami riguardanti la presente Privacy Policy
                            o su come trattiamo i dati personali, l’utente è invitato a contattarci utilizzando
                            i dettagli di contatto di seguito.
                        </p>
                        <p>
                            Se l’utente sporge reclamo, lo esamineremo e forniremo una risposta entro un termine
                            ragionevole, a condizione di disporre di tutte le informazioni necessarie e di aver
                            completato qualsiasi indagine richiesta. In alcuni casi, potremmo aver bisogno di
                            chiedere all’utente di mettere il reclamo per iscritto per essere sicuri di comprenderlo.
                            Potremmo inoltre aver bisogno di chiedere all’utente ulteriori informazioni o di
                            verificare la sua identità. Cercheremo di confermare se appropriato e necessario
                            con voi la vostra comprensione della condotta rilevante per il reclamo e
                            di ciò che vi aspettate come risultato. Tratteremo il reclamo in modo confidenziale
                            e risponderemo entro un termine ragionevole, solitamente per iscritto.
                        </p>
                        <p>
                            Se non sei soddisfatto del risultato, ti preghiamo di contattarci.
                            In alternativa, hai il diritto di presentare reclamo al regolatore della privacy nel tuo paese.
                        </p>
                        <br />
                        <br />
                        <br />
                        <IonButton routerLink='/support'>Assistenza</IonButton>
                    </IonGrid>
                </IonContent>

            </IonPage>
        );
    }

}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
