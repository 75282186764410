import React from 'react';
import {
	IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
	IonGrid, IonRow, IonCard, IonCardContent, IonCardHeader,
	IonList, IonItem, IonIcon, IonButton, IonButtons, IonBackButton,
	IonInput, IonSelect, IonSelectOption, IonLabel
} from '@ionic/react';
import { connect } from 'react-redux';
import { DocumentFontSettings, Highlight, settingsServices } from '../store/services';
import { add, ear, remove, removeCircleOutline } from 'ionicons/icons';
import { ColorPickerPopover } from '../components/ColorPickerPopover';
import { fromQueryToObjOfParams } from '../../../common/queryUtils';
import { ttsServices } from '../../tts/store/services';
import { TTSSettings } from '../../tts/store/reducers';
import i18n from '../../../i18n';
import { Trans } from 'react-i18next';
import { auth } from '../../../firebaseConfig';
import "./Settings.css";

type Props = {
	history: any,
}

type State = {
	highlightings: Highlight[],
	documentFontSettings: DocumentFontSettings | null,
	ttsSettings: TTSSettings | null,


	highlightTargetText: string,
	showColorPicker: boolean,
	pickedColor: string,

	///
	userUuid: string | null,
}

class Settings extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			highlightings: [],
			documentFontSettings: null,
			ttsSettings: null,

			highlightTargetText: '',
			showColorPicker: false,
			pickedColor: '#0079BF',

			///
			userUuid: null,
		}
	}

	componentDidMount() {
		auth.onAuthStateChanged(userData => {
			if (userData) {
				this.setState({ userUuid: userData.uid });

				settingsServices.getHighlightings(userData.uid)
					.then(highlightings => {
						this.setState({ highlightings: highlightings });
					})
					.catch(err => {
						console.error("[Settings] error getting highlightings:", err);
					});


				settingsServices.getDocumentViewingSettings(userData.uid)
					.then(documentViewingSettings => {
						this.setState({ documentFontSettings: documentViewingSettings });
					})
					.catch(err => {
						console.error('[Settings] error getting viewing settings', err);
					});

				ttsServices.getTTSSettings(userData.uid).then(ttsSettings => {
					this.setState({ ttsSettings: ttsSettings })
				})
					.catch(err => {
						console.log('[Settings] error getting TTS settings', err);
					})
			}
			else {
				this.setState({ userUuid: null });
			}
		})

		if (this.props.history.location.search !== '') {
			const ltm = fromQueryToObjOfParams(this.props.history.location.search as string)["ltm"];
			this.setState({
				highlightTargetText: ltm,
			});
			(document.getElementById('lettersToMarkInput') as HTMLIonInputElement).focus();
		}
	}

	playAudio() {
		let gender = this.state.ttsSettings?.voiceType === 'F' ? 'Female' : 'Male';
		let prosody_rate = this.state.ttsSettings?.prosodyRate === 'slow' ? 'Slow' : this.state.ttsSettings?.prosodyRate === 'medium' ? 'Medium' : 'Fast';
		let language = i18n.language === 'en' ? 'EN' : i18n.language === 'fr' ? 'FR' : i18n.language === 'es' ? 'ES' : 'IT';
		let src = require('../../../assets/speech-samples/' + gender + prosody_rate + language + '.mp3');
		let audio = new Audio(src);
		audio.play();
	}


	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref='/' />
						</IonButtons>
						<IonTitle><Trans>Impostazioni</Trans></IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent fullscreen>
					<IonGrid className='settingsGrid'>
						<IonCard mode='md' className='settingsCard'>
							<IonCardHeader>
								<IonTitle>
									<Trans>Evidenziazioni</Trans> {/* TO LOCALIZE THIS */}
								</IonTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList
									lines='none'
								>
									{
										this.state.highlightings.map(highlight => {
											return (
												<IonItem
													key={highlight.uuid}
													className='settingsHighlightItem'
												>
													<IonGrid className='settingsHighlightItemGrid'>
														<IonRow className='settingsHighlightItemGridRow'>
															{highlight.target}
														</IonRow>

														<IonRow className='settingsHighlightItemGridColorRow'>
															<div
																className='settingsColorDiv'
																style={{ background: highlight.color }}
															>
															</div>
														</IonRow>

														<IonRow className='settingsHighlightItemGridRemoveRow'>
															<IonButton
																fill='clear'
																color='danger'
																shape='round'
																onClick={() => {
																	if (this.state.userUuid) {
																		settingsServices.deleteHighlighting(this.state.userUuid, highlight.uuid)
																		.then(() => {
																			this.setState({
																				highlightings: this.state.highlightings.filter(highlightPref => highlightPref.uuid !== highlight.uuid)
																			});
																		})
																		.catch((err) => {
																			console.error("[Settings] error deleting highlighting id:", highlight.uuid, "error:", err);
																		});
																	}
																}}
															>
																<IonIcon
																	icon={removeCircleOutline}
																/>
															</IonButton>
														</IonRow>
													</IonGrid>
												</IonItem>
											)
										})
									}
									<IonItem>
										<div
											className='settingsHighlightingMainDiv'
										>
											<IonLabel
												className='settingsHighlightPlaceholder'
												hidden={this.state.highlightTargetText === '' ? false : true}
											>
												<Trans style={{ textOverflow: 'ellipsis' }}>Lettere da evidenziare</Trans> {/* TO LOCALIZE THIS */}
											</IonLabel>

											<IonInput
												id='lettersToMarkInput'
												style={{ textOverflow: 'ellipsis' }}
												onIonChange={(e) => {
													this.setState({
														highlightTargetText: e.detail.value ? e.detail.value : '',
													})
												}}
												value={this.state.highlightTargetText}
											/>

											<div
												slot='end'
												onClick={() => {
													this.setState({ showColorPicker: true })
												}}
												className='settingsColorDiv'
												style={{ background: this.state.pickedColor }}
											>
											</div>

											<IonButton
												slot='end'
												disabled={this.state.highlightTargetText === ''}
												onClick={() => {
													if (this.state.userUuid) {
														settingsServices.addHighlighting(this.state.userUuid, this.state.highlightTargetText, this.state.pickedColor)
														.then((highlight) => {
															const arrayCopy = this.state.highlightings.slice();
															arrayCopy.push(highlight);
															this.setState({
																highlightings: arrayCopy,
																highlightTargetText: '',
															});
														})
														.catch((err) => {
															console.error("[Settings] error adding highlighting:", err);
														})
													}
												}}
											>
												<Trans>Salva</Trans>
											</IonButton>

										</div>
									</IonItem>

								</IonList>
							</IonCardContent>
						</IonCard>


						{
							this.state.ttsSettings &&
							<IonCard mode='md' className='settingsCard'>
								<IonCardHeader>
									<IonTitle>
										<Trans>Text To Speech</Trans>
									</IonTitle>
								</IonCardHeader>
								<IonCardContent>
									<IonList
										lines='none'
									>
										<IonItem>
											<div className='settingsDocumentViewingItemDiv'>
												<p className='settingsDocumentViewingParagraph'>
													<Trans>Velocità di riproduzione</Trans>
												</p>
												<IonSelect
													id='playbackSpeedPicker'
													className='settingsDocumentViewingFontPicker'
													value={this.state.ttsSettings.prosodyRate}
													interface='popover'
													onIonChange={e => {
														if (e.detail.value === '')
															return
														if (this.state.userUuid) {
															ttsServices.setProsodyRate(this.state.userUuid, e.detail.value)
															.then(ttsSettings => this.setState({ ttsSettings: ttsSettings }))
															.catch(err => {
																console.error('[Settings] Error changing voice prosody rate', err);
															})
														}
													}}
												>
													<IonSelectOption value="slow"><Trans>Lento</Trans></IonSelectOption> {/* TO BE LOCALIZED */}
													<IonSelectOption value="medium"><Trans>Medio</Trans></IonSelectOption> {/* TO BE LOCALIZED */}
													<IonSelectOption value="fast"><Trans>Veloce</Trans></IonSelectOption> {/* TO BE LOCALIZED */}
												</IonSelect>
											</div>
										</IonItem>
										<IonItem>
											<div className='settingsDocumentViewingItemDiv'>
												<p className='settingsDocumentViewingParagraph'>
													<Trans>Genere</Trans>
												</p>
												<IonSelect
													id='genderPicker'
													className='settingsDocumentViewingFontPicker'
													value={this.state.ttsSettings.voiceType}
													interface='popover'
													onIonChange={e => {
														if (e.detail.value === '')
															return
														if (this.state.userUuid) {
															ttsServices.setVoiceType(this.state.userUuid, e.detail.value)
															.then(ttsSettings => this.setState({ ttsSettings: ttsSettings }))
															.catch(err => {
																console.error('[Settings] Error changing voice gender', err);
															})
														}
													}}
												>
													<IonSelectOption value="M"><Trans>Voce 1</Trans></IonSelectOption>
													<IonSelectOption value="F"><Trans>Voce 2</Trans></IonSelectOption>
												</IonSelect>
											</div>

										</IonItem>
										<IonItem>
											<div className="settingsDocumentViewingItemDiv">
												<IonButton
													fill='clear'
													onClick={() => this.playAudio()}
												>
													<IonIcon
														className="settingVoicePreviewButtonIcon"
														icon={ear}
													/>
													<Trans>Ascolta la voce</Trans> {/* TO BE LOCALIZED */}
												</IonButton>
											</div>
										</IonItem>

									</IonList>
								</IonCardContent>
							</IonCard>
						}


						<IonCard mode='md' className='settingsCard'>
							<IonCardHeader>
								<IonTitle>
									<Trans>Impostazioni font</Trans>
								</IonTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									<IonItem>
										<div className='settingsDocumentViewingItemDiv'>
											<p className='settingsDocumentViewingParagraph'>
												Font
											</p>
											<IonSelect
												className='settingsDocumentViewingFontPicker'
												value={this.state.documentFontSettings ? this.state.documentFontSettings.font : "arial"}
												interface='popover'
												onIonChange={e => {
													if (this.state.userUuid) {
														settingsServices.editFont(this.state.userUuid, e.detail.value)
															.then(documentViewingSettings => {
																this.setState({ documentFontSettings: documentViewingSettings });
															})
															.catch(err => {
																console.error('[Settings] Error changing font:', err);
															});
													}
												}}
											>
												<IonSelectOption value="arial">Arial</IonSelectOption>
												<IonSelectOption value="georgia">Georgia</IonSelectOption>
												<IonSelectOption value="tahoma">Tahoma</IonSelectOption>
												<IonSelectOption value="verdana">Verdana</IonSelectOption>
												<IonSelectOption value="easy_reading">Easy reading</IonSelectOption>
											</IonSelect>
										</div>
									</IonItem>

									<IonItem>
										<div className='settingsDocumentViewingItemDiv'>
											<p className='settingsDocumentViewingParagraph'>
												<Trans>Dimensione font</Trans>
											</p>
											<div className='settingsDocumentViewingButtonsDiv'>
												<IonButton
													fill='clear'
													onClick={() => {
														const fontSizeInput: HTMLIonInputElement = document.getElementById('settingsFontSizeInput') as HTMLIonInputElement;
														if (fontSizeInput.value && (((fontSizeInput.value as number) - 5) > 0)) {
															fontSizeInput.value = this.state.documentFontSettings!.fontSize - 5;
														}
													}}
												>
													<IonIcon
														icon={remove}
													/>
												</IonButton>

												<IonInput
													id='settingsFontSizeInput'
													className='settingsNumericInput'
													type='number'
													value={this.state.documentFontSettings ? this.state.documentFontSettings.fontSize : 30}
													debounce={300}
													onIonChange={(e) => {
														if (this.state.documentFontSettings && this.state.userUuid) {
															settingsServices.editFontSize(this.state.userUuid, Number(e.detail.value))
																.then(documentViewingSettings => {
																	//console.log('[Settings] editing font size success', documentViewingSettings);
																	this.setState({ documentFontSettings: documentViewingSettings });
																})
																.catch(err => {
																	//console.log('[Settings] error editing font size:', err);
																})
														}
													}}
												/>

												<IonButton
													fill='clear'
													onClick={() => {
														const fontSizeInput: HTMLIonInputElement = document.getElementById('settingsFontSizeInput') as HTMLIonInputElement;
														fontSizeInput.value = this.state.documentFontSettings!.fontSize + 5;
													}}
												>
													<IonIcon
														icon={add}
													/>
												</IonButton>
											</div>
										</div>
									</IonItem>

									<IonItem>
										<div className='settingsDocumentViewingItemDiv'>
											<p className='settingsDocumentViewingParagraph'>
												<Trans>Interlinea</Trans>
											</p>
											<div className='settingsDocumentViewingButtonsDiv'>
												<IonButton
													fill='clear'
													onClick={() => {
														const lineLeadingInput: HTMLIonInputElement = document.getElementById('settingsLineLeadingInput') as HTMLIonInputElement;
														if (lineLeadingInput.value && (((lineLeadingInput.value as number) - 5) > 0)) {
															lineLeadingInput.value = this.state.documentFontSettings!.lineLeading - 5;
														}
													}}
												>
													<IonIcon
														icon={remove}
													/>
												</IonButton>

												<IonInput
													id='settingsLineLeadingInput'
													className='settingsNumericInput'
													type='number'
													value={this.state.documentFontSettings ? this.state.documentFontSettings.lineLeading : 0}
													debounce={300}
													onIonChange={(e) => {
														if (this.state.documentFontSettings && this.state.userUuid) {
															settingsServices.editLineLeading(this.state.userUuid, Number(e.detail.value))
																.then(documentViewingSettings => {
																	//console.log('[Settings] editing line leading success', documentViewingSettings);
																	this.setState({ documentFontSettings: documentViewingSettings });
																})
																.catch(err => {
																	console.error('[Settings] error editing line leading:', err);
																})
														}
													}}
												/>

												<IonButton
													fill='clear'
													onClick={() => {
														const lineLeadingInput: HTMLIonInputElement = document.getElementById('settingsLineLeadingInput') as HTMLIonInputElement;
														lineLeadingInput.value = this.state.documentFontSettings!.lineLeading + 5;
													}}
												>
													<IonIcon
														icon={add}
													/>
												</IonButton>
											</div>
										</div>
									</IonItem>

									<IonItem>
										<div className='settingsDocumentViewingItemPreviewDiv'>
											<p className='settingsDocumentViewingPreviewHeaderParagraph'>
												<Trans>Anteprima</Trans>
											</p>
											<p
												className='settingsDocumentViewingPreviewParagraph'
												style={{
													fontFamily: settingsServices.fromBackendFontNameToCssFontFamily(this.state.documentFontSettings ? this.state.documentFontSettings.font : "Arial"),
													fontSize: this.state.documentFontSettings ? `${this.state.documentFontSettings.fontSize}px` : "30px",
													lineHeight: this.state.documentFontSettings ? `${100 + this.state.documentFontSettings.lineLeading}%` : "100%",
												}}
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit.
												Praesent at est malesuada justo tincidunt faucibus commodo nec dolor.
												Suspendisse sit amet mauris sit amet massa sodales bibendum.
											</p>
										</div>
									</IonItem>
								</IonList>

								<div className="settingsEasyReadingAttributionDiv">
									<p className="settingsEasyReadingAttributionParagraph">
										L'app Reading Easy utilizza anche il carattere ad alta leggibilità <a href="https://www.easyreading.it" target="_blank" rel="noopener noreferrer">EasyReading / Dyslexia Friendly</a>. {/* TO BE LOCALIZED */}
									</p>
								</div>

							</IonCardContent>
						</IonCard>

						<div className="settingsLicensesButtonDiv">
							<IonButton
								color="medium"
								onClick={() => {
									this.props.history.push('/legal/licenses');
								}}
								fill="clear"
								class="settingsLicensesButton"
							>
								<Trans>Licenze</Trans>
							</IonButton>
						</div>
					</IonGrid>
				</IonContent>

				<ColorPickerPopover
					isOpen={this.state.showColorPicker}

					selectedColor={(color) => {
						//console.log('[Settings] selected color:', color);
						if (color) {
							this.setState({ pickedColor: color })
						}
					}}

					onDidDismiss={() => {
						this.setState({
							showColorPicker: false,
						})
					}}
				/>
			</IonPage >
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);