import {
    IonBackButton, IonButton, IonButtons, IonHeader, IonIcon,
    IonProgressBar, IonTitle, IonToolbar, isPlatform
} from '@ionic/react';
import React from 'react';
import voiceOff from '../../../assets/icons/voiceOff.svg';
import voiceOn from '../../../assets/icons/voiceOn.svg';
import { Document } from '../store/reducers';
import './FileViewerHeader.css';

type Props = {
    document: Document | null;
    isLoadingFile: boolean;
    showPlayer: boolean;
    showFileViewerPlayer: (show: boolean) => void;
    popoverButtonClicked: () => void;
}

type State = {
    showPopoverButton: boolean | null
}

class FileViewerHeader extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showPopoverButton: null
        }
    }
    showPopoverButton(show: boolean) {
        this.setState({ showPopoverButton: show });
    }

    render() {
        return (
            <IonHeader id='header' mode='md'>
                <IonToolbar>
                    <IonTitle>
                        {this.props.document ? this.props.document.name : ''}
                    </IonTitle>

                    <IonButtons slot="start">
                        <IonBackButton defaultHref='/' />
                    </IonButtons>

                    {
                        this.props.isLoadingFile &&
                        <IonProgressBar
                            type='indeterminate'
                        />
                    }

                    <IonButtons slot="end">
                        {
                            isPlatform("desktop") &&
                            <IonButton hidden={!this.state.showPopoverButton} onClick={() => this.props.popoverButtonClicked()}>
                                Analizza
                            </IonButton>
                        }

                        <IonButton
                            onClick={() => {
                                this.props.showFileViewerPlayer(!this.props.showPlayer)
                            }}
                        >
                            <IonIcon
                                className='fileViewerHeaderTtsIcon'
                                size='large'
                                icon={!this.props.showPlayer ? voiceOff : voiceOn}
                            />
                        </IonButton>
                    </IonButtons>

                </IonToolbar>
            </IonHeader>
        );
    }
}

export default FileViewerHeader;