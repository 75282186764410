import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from './assets/translations/en';
import { fr } from "./assets/translations/fr";
import { it } from './assets/translations/it';
import { es } from './assets/translations/es';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en.mergedObject
    },
    it: {
      translations: it.mergedObject
    },
    fr: {
      translations: fr.mergedObject
    },
    es:{
      translations: es.mergedObject
    }
  },
  fallbackLng: "it", //changed from 'en' to 'it' since the app default language is italian
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;