import {
    IonButton, IonPopover
} from '@ionic/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import './ColorPickerPopover.css';

interface Day {
    date: Date,
    dayOfMonth: number,
    isCurrentMonth: boolean,
}

type Props = {
    isOpen: boolean,
    popoverEvent?: any,
    showResetButton?: boolean,
    selectedColor: (color: string | null) => void,
    onDidDismiss?: () => void,
};

type State = {
    selectedColor: string | null,
    predefinedColors: Color[],
};

interface Color {
    color: string,
    name: string,
}


export class ColorPickerPopover extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedColor: null,
            predefinedColors: [
                { color: "#33adff", name: 'Blu' }, // TO LOCALIZE THIS
                { color: "#ffab3d", name: 'Arancione' },
                { color: "#54ff71", name: 'Verde' },
                { color: "#ff4a4a", name: 'Rosso' },
                { color: "#ff78e6", name: 'Rosa' },
                { color: '#cccccc', name: 'Grigio' }],
        }
    }

    dismissColorPicker() {
        if (this.props.onDidDismiss) {
            this.props.selectedColor(this.state.selectedColor);
            this.props.onDidDismiss();
        }

    }
    numberToHex(c: number) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    rgbToHex(r: number, g: number, b: number) {
        return "#" + this.numberToHex(r) + this.numberToHex(g) + this.numberToHex(b);
    }
    generateColor(color: string) {
        switch (color) {
            case 'blue': {
                let g = Math.floor(Math.random() * 256)
                return this.rgbToHex(77, g, 255);
            }
            case 'orange': {
                let g = Math.floor(Math.random() * 70) + 130
                return this.rgbToHex(255, g, 64);
            }
            case 'green': {
                let g = Math.floor(Math.random() * 55) + 200
                return this.rgbToHex(77, g, 7);
            }
            case 'red': {
                let g = Math.floor(Math.random() * 140) + 0
                let b = Math.floor(Math.random() * 140) + 0
                return this.rgbToHex(255, g, b);
            }
            case 'pink': {
                let g = Math.floor(Math.random() * 60) + 120
                return this.rgbToHex(255, g, 240);
            }
        }
        //return '#' +  Math.random().toString(16).substr(-6);
    }
    render() {
        return (
            <IonPopover
                event={this.props.popoverEvent}
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.setState({ selectedColor: null })
                        this.props.onDidDismiss();
                    }
                }}
                mode='md'
                cssClass='colorPickerPopover'
            >
                <h1 className='colorPickerPopoverTitle'>
                    Seleziona il colore
                </h1>
                <div className='colorPickerPopoverColorsDiv'>
                    {
                        this.state.predefinedColors.map(color => {
                            return (
                                <div key={color.color}>
                                    <IonButton
                                        fill='clear'
                                        className='colorPickerPopoverColorButton'
                                        onClick={() => {
                                            this.setState({
                                                selectedColor: color.color
                                            })
                                            this.props.selectedColor(color.color);
                                        }}
                                    >
                                        <div
                                            className={this.state.selectedColor === color.color ? 'colorPickerPopoverSelectedColorItemDiv' : 'colorPickerPopoverColorItemDiv'}
                                            style={isMobile ? { width: '7vh', height: '7vh', background: color.color } : { background: color.color }}>
                                        </div>
                                    </IonButton>
                                    <p className='colorPickerPopoverColorNameParagraph'>
                                        {color.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    {this.props.showResetButton &&
                        <div className='colorPickerPopoverResetButtonDiv'>
                            <IonButton
                                fill='outline'
                                onClick={() => {
                                    this.setState({
                                        selectedColor: "#000000"
                                    })
                                    this.props.selectedColor("#000000");
                                    this.dismissColorPicker();
                                }}>
                                Reset
                            </IonButton>
                        </div>
                    }
                    <div className='colorPickerPopoverEndButtonDiv'>
                        <IonButton
                            fill='outline'
                            onClick={() => {
                                this.dismissColorPicker();
                            }}
                        >
                            Fine
                        </IonButton>
                    </div>
                </div>

            </IonPopover>
        );
    }
}




