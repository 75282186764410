export const MenuTranslationsEN = {
    "Gestione File": "File Manager",
    "Impostazioni": "Settings",
    "Profilo": "Profile",
    "Esci": "Logout",
    "Termini e Condizioni": "Terms and Conditions",
}
export const MenuTranslationsIT = {
    "Gestione File": "Gestione File",
    "Impostazioni": "Impostazioni",
    "Profilo": "Profilo",
    "Esci": "Esci",
    "Termini e Condizioni": "Termini e Condizioni",
}
export const MenuTranslationsES = {
    "Gestione File": "Gestione File",
    "Impostazioni": "Impostazioni",
    "Profilo": "Profilo",
    "Esci": "Esci",
    "Termini e Condizioni": "Termini e Condizioni",
}
export const MenuTranslationsFR = {
    "Gestione File": "Gestione File",
    "Impostazioni": "Impostazioni",
    "Profilo": "Profilo",
    "Esci": "Esci",
    "Termini e Condizioni": "Termini e Condizioni",
}