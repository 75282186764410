import {SettingsTranslationsFR} from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsFR } from '../../modules/user/ProfileTranslations';

const mergedObject = {
    ...SettingsTranslationsFR,
    ...ProfileTranslationsFR,
  };
  
export const fr = {
    mergedObject,
}