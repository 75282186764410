export const PopoverTranslationsEN = {
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Click on a word to analyze it",
    "Leggi Frase": "Read the sentence",
    "Cambia la lingua della frase":"Change sentence language",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta":"Read word",
    "Dizionario":"Dictionary",
    "Evidenzia lettere":"Letter highlight",
    "Disegna immagine":"Draw image",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre":"Always highlight",
    "Seleziona lettere":"Select letters"
}
export const PopoverTranslationsIT = {
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Clicca una parola per analizzarla",
    "Leggi Frase": "Leggi Frase",
    "Cambia la lingua della frase":"Cambia la lingua della frase",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta":"Ascolta",
    "Dizionario":"Dizionario",
    "Evidenzia lettere":"Evidenzia lettere",
    "Disegna immagine":"Disegna immagine",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre":"Evidenzia sempre",
    "Seleziona lettere":"Seleziona lettere"
}
export const PopoverTranslationsES = {
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Click on a word to analyze it",
    "Leggi Frase": "Read the sentence",
    "Cambia la lingua della frase":"Change sentence language",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta":"Read word",
    "Dizionario":"Dictionary",
    "Evidenzia lettere":"Letter highlight",
    "Disegna immagine":"Draw image",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre":"Always highlight",
    "Seleziona lettere":"Select letters"
}
export const PopoverTranslationsFR = {
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Click on a word to analyze it",
    "Leggi Frase": "Read the sentence",
    "Cambia la lingua della frase":"Change sentence language",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta":"Read word",
    "Dizionario":"Dictionary",
    "Evidenzia lettere":"Letter highlight",
    "Disegna immagine":"Draw image",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre":"Always highlight",
    "Seleziona lettere":"Select letters"
}