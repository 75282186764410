import React from 'react';
import { IonButton, IonGrid, IonIcon, IonImg, IonProgressBar, IonRow } from '@ionic/react';
import { book, volumeHigh } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { getTTS } from '../common/ttsServices';
import './WordActions.css';
import { imagesSearch } from '../common/imagesSearch';
import freeLineIcon from '../../../assets/icons/freeLine.svg'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, firestore, storage } from '../../../firebaseConfig';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { ttsServices } from '../../tts/store/services';
import i18n from '../../../i18n';

type Props = {
    selectedText: string | null,
    history: any,

    onDictionaryClicked?: (text: string | null) => void,
    /* onHighlightClicked?: (word: SentenceWord) => void, */
    /* onSketchClicked?: (word: SentenceWord) => void, */
    showDrawImage?: boolean,
};

type State = {
    imageSearchResponse: any | undefined,
    wordPicture: string,
    isLoadingImages: boolean,
};


class WordActions extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            imageSearchResponse: undefined,
            wordPicture: '',
            isLoadingImages: false,
        }
    }

    async componentDidUpdate(prev: Props) {
        if ((prev.selectedText !== this.props.selectedText) && this.props.selectedText) {
            this.setState({
                imageSearchResponse: [],
                isLoadingImages: true,
                wordPicture: '',
            });

            const q = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", this.props.selectedText.trim()));
            
            imagesSearch(this.props.selectedText)
            .then(images => {
                this.setState({ imageSearchResponse: images }, async () => {
                    const querySnapshot = await getDocs(q);
                    console.log("[WordActions] query snapshot: ", querySnapshot);

                    if (!querySnapshot.empty)
                        querySnapshot.forEach((doc) => {
                            console.log("[WordActions] snapshot: ", doc.data());

                            this.state.imageSearchResponse.push(
                                this.fromGSUrltoUrl(doc.data().p),
                            )
                        })
                    else {
                        this.setState({ isLoadingImages: false });
                    }
                });
            })
            .catch(err => {
                console.error("[WordActions] error calling imageSearch:", err);
            });
        }
    }
    fromGSUrltoUrl(gsUrl: string) {
        return new Promise<string>((resolve, reject) => {
            getDownloadURL(storageRef(storage, gsUrl))
            .then(url => {
                this.setState({ wordPicture: url, isLoadingImages: false, })
                resolve(url);
            })
            .catch(err => {
                //console.log('[fromGSUrltoUrl] service error:', err);
                reject(err);
            })
        });
    }

    render() {
        return (
            <div>
                {
                    (this.state.imageSearchResponse /*  && (this.state.imageSearchResponse.hits.length > 0) */) &&
                    <div className='wordActionImagesRow' style={{ height: this.state.isLoadingImages || this.state.imageSearchResponse.length === 0 ? '100px' : '200px' }}>
                        {
                            this.state.isLoadingImages &&
                            <IonProgressBar type="indeterminate"></IonProgressBar>
                        }

                        {
                            this.state.wordPicture && !this.state.isLoadingImages &&
                            <>
                                {
                                    this.state.wordPicture &&
                                    <IonImg
                                        onClick={() => this.props.history.push({
                                            pathname: '/sketch',
                                            state: { word: this.props.selectedText?.trim(), drawing: this.state.wordPicture }
                                        })}
                                        key={this.state.wordPicture}
                                        className='wordActionsImage'
                                        src={this.state.wordPicture}
                                    />
                                }
                            </>
                        }
                        {
                            this.state.imageSearchResponse.length > 0 && !this.state.isLoadingImages &&
                            /* this.state.imageSearchResponse.hits.length > 0 && */
                            <>
                                {
                                    this.state.imageSearchResponse.map((image: string, key: number) => {
                                        return (
                                            <IonImg
                                                key={key}
                                                className='wordActionsImage'
                                                src={`data:image/png;base64,${image}`}
                                            //alt={hit.tags}
                                            />
                                        )
                                    })
                                }

                            </>
                        }
                        {
                            this.state.imageSearchResponse.length === 0 && !this.state.isLoadingImages &&
                            <>
                                <p>Non ci sono immagini</p> {/* TO LOCALIZE THIS */}
                                <IonButton fill='clear' style={{ textTransform: 'none' }}>disegnane una</IonButton>
                            </>
                        }
                    </div>
                }

                <p
                    className='wordActionsWordParagraph'
                >
                    {this.props.selectedText ? this.props.selectedText.toLocaleUpperCase() : ""}
                </p>

                <IonGrid>
                    <IonRow>
                        <IonButton
                            className='wordActionsActionButton'
                            onClick={() => {
                                if (this.props.selectedText) {
                                    ttsServices.getTTSSettings(auth.currentUser!.uid)
                                        .then(ttsSettings => {
                                            console.log("TTS SETTINGS", ttsSettings);

                                            getTTS(this.props.selectedText!.trim(), i18n.language, ttsSettings)
                                                .then((base64Audio) => {
                                                    let elemAudio = document.createElement('audio');
                                                    document.body.appendChild(elemAudio);
                                                    elemAudio.controls = true;
                                                    elemAudio.src = base64Audio;
                                                    elemAudio.hidden = true;
                                                    elemAudio.play();
                                                })
                                                .catch(err => {
                                                    console.error("[WordActions] error getting tts:", err);
                                                });
                                        })
                                }
                            }}
                        >
                            <IonIcon
                                icon={volumeHigh}
                            />
                        </IonButton>
                        <p className='wordActionsActionParagraph'>
                            <Trans>Ascolta</Trans>
                        </p>

                    </IonRow>
                    {
                        <IonRow>
                            <IonButton
                                className='wordActionsActionButton'
                                onClick={() => {
                                    if (this.props.onDictionaryClicked && this.props.selectedText) {
                                        this.props.onDictionaryClicked(this.props.selectedText)
                                    }
                                }}
                            >
                                <IonIcon
                                    icon={book}
                                />
                            </IonButton>
                            <p className='wordActionsActionParagraph'>
                                <Trans>Dizionario</Trans>
                            </p>

                        </IonRow>
                    }
                    {/* <IonRow>
                        <IonButton
                            className='wordActionsActionButton'
                            onClick={() => {
                                if (this.props.onHighlightClicked && this.props.wordObj) {
                                    this.props.onHighlightClicked(this.props.wordObj)
                                }
                            }}
                        >
                            <IonIcon
                                icon={markerIcon}
                            />
                        </IonButton>
                        <p className='wordActionsActionParagraph'>
                            <Trans>Evidenzia lettere</Trans>
                        </p>

                    </IonRow> */}
                    {
                        this.props.selectedText &&
                        ((this.props.showDrawImage === undefined ) || (this.props.showDrawImage === true)) &&
                        <IonRow>
                            <IonButton
                                className='wordActionsActionButton'
                                onClick={() => this.props.history.push({
                                    pathname: '/sketch',
                                    state: { word: this.props.selectedText?.trim(), drawing: this.state.wordPicture }
                                })}
                            >
                                <IonIcon
                                    style={{ filter: "invert(100%)" }}
                                    icon={freeLineIcon}
                                />
                            </IonButton>
                            <p className='wordActionsActionParagraph'>
                                <Trans>Disegna immagine</Trans>
                            </p>
                        </IonRow>
                    }
                </IonGrid>
            </div >
        );
    }
}

export default WordActions;