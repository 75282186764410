import axios from "axios";

export function getTextMeanings(word: string) {
    return new Promise<any[]>((resolve, reject) => {
        axios.post(`https://fujjvnytml.execute-api.eu-central-1.amazonaws.com/staging/word-meanings/`, { word: word })
            .then(response => {
                console.log('[getWordMeanings] response:', response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[getWordMeanings] error:', error.response.data);
                reject(error.response.data);
            });
    })
}