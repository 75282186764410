import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { userServices } from '../modules/user/store/services';
const { PushNotifications } = Plugins;
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');


function pushNotificationHandler() {
    if (isPushNotificationsAvailable) {

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: PushNotificationToken) => {
                //console.log('[pushNotificationHandler] token:', JSON.stringify(token));
                userServices.addDevice(token.value)
                    .then(data => {
                        //console.log('[pushNotificationHandler - addDevice service] data:', JSON.stringify(data));
                    })
                    .catch(err => {
                        //console.log('[pushNotificationHandler - addDevice service] error:', JSON.stringify(err));
                    });
            }
        );

        // Some issue with your setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                //console.log('[pushNotificationHandler - registration error] error:', JSON.stringify(error));
                alert('Impossibile attivare le notifiche. Riprovare.'); // TO LOCALIZE THIS
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotification) => {
                //console.log('[pushNotificationHandler - notification received] notification:', JSON.stringify(notification));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {
                //console.log('[pushNotificationHandler - pushNotificationActionPerformed] notification:', JSON.stringify(notification));

                /* TO TRACKING NOTIFICATION OPENING */


                /* CALL TO ACTION URL */
                if (notification.notification.data.callToActionUrl) {
                    try {
                        let callToActionUrl = new URL(notification.notification.data.callToActionUrl);
                        //console.log('Url:', callToActionUrl); // TO REMOVE
                        if (callToActionUrl && callToActionUrl.toString().startsWith('https://reading-easy.it')) {
                            window.location.replace(callToActionUrl.pathname + callToActionUrl.search);
                        }
                        else {
                            window.open(callToActionUrl.toString(), '_system');
                        }
                    }
                    catch (e2) {
                        //console.log('Impossible construct call to action url from notification:', JSON.stringify(e2));
                    }
                }
            }
        );
    }
}

export default pushNotificationHandler;