
import React from 'react';
import {
    IonButton, IonGrid, IonIcon, IonRow, IonSlide,
} from '@ionic/react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import reader from '../../../assets/illustrations/reader.json';
// animazione: https://lottiefiles.com/47229-the-reader

import './OnboardingSlide.css';
import { arrowForward } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';

type Props = {
}

type State = {
}

class OnboardingReasy extends React.Component<Props, State> {

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: reader,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <IonSlide className='onboardingSlide'>
                <IonGrid className='onboardingSlideGrid'>

                    <IonRow className='onboardingSlideAnimationRow'>
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={defaultOptions}
                            height={isMobile ? 200 : 400}
                            width={isMobile ? 200 : 400}
                        />
                    </IonRow>

                    <IonRow className='onboardingSlideHeaderRow'>
                        <h2 className='onboardingSlideHeader'>
                            <p>
                                <a style={{ color: 'red' }}>Rea</a>lly
                                Ea<a style={{ color: 'red' }}>sy</a>
                            </p>
                        </h2>
                    </IonRow>

                    <IonRow className='onboardingSlideBodyRow'>
                        <p className='onboardingSlideBodyParagraph'>
                            <Trans>Lo strumento per semplificare lo studio</Trans>
                        </p>
                    </IonRow>

                    <IonRow className='onboardingSlideCTAButtonRow'>
                        <IonButton
                            className='onboardingSlideCTAButton'
                            onClick={() => {
                                (document.getElementById('onboardingSlides') as HTMLIonSlidesElement)
                                    .slideNext()
                                    .then(() => { })
                                    .catch(() => { });
                            }}
                            shape='round'
                            fill='outline'
                            color='medium'
                        >
                            <Trans>Avanti</Trans>
                            <IonIcon
                                icon={arrowForward}
                            />
                        </IonButton>
                    </IonRow>

                </IonGrid>
            </IonSlide>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingReasy);