import {SettingsTranslationsES} from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsES } from '../../modules/user/ProfileTranslations';

const mergedObject = {
    ...SettingsTranslationsES,
    ...ProfileTranslationsES,
  };
  
export const es = {
    mergedObject,
}