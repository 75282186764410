import { Chooser } from '@ionic-native/chooser';
import {
    IonButtons, IonContent, IonFab, IonFabButton,
    IonGrid, IonHeader, IonIcon, IonPage, IonRow,
    IonToolbar, IonCard, IonCardContent, IonImg, isPlatform,
    IonAlert, IonProgressBar, IonMenuButton, IonPopover,
    IonItem, IonText, IonTitle,
} from '@ionic/react';
import { add, trash } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { filesServices } from '../store/services';
import { Document } from '../store/reducers';
import SideMenu from '../../menu/SideMenu';
import Dropzone from 'react-dropzone';
import Lottie from 'react-lottie';
import tapAnimation from '../../../assets/illustrations/tap.json';
import { Trans } from 'react-i18next';
import { Plugins } from '@capacitor/core';
import notificationsImage from '../../../assets/icons/notify.svg';
import previewSampleImage from '../../../assets/images/previewSample.svg';
import { auth, firestore } from '../../../firebaseConfig';
import { collection, onSnapshot, query, Unsubscribe, where } from "firebase/firestore"
import './FilesManager.css';
import { userServices } from '../../user/store/services';
import { Device } from '@ionic-native/device';
const { PushNotifications } = Plugins;

type Props = {
    history: any,
}

type State = {
    isLoadingFoldersAndFiles: boolean,
    documents: Document[],

    showFilePopover: boolean,
    filePopoverEvent: Event | undefined,
    showDeleteFileAlert: boolean,

    dragOverDropzone: boolean,

    /////////
    userUuid: string | null,
    addDeviceBannerVisible: boolean,
    unsubscribeOnFilesChanges: Unsubscribe | null,
    selectedFileWithContextMenu: string | null,
}

class FilesManager extends React.Component<Props, State> {
    fileRef: any;
    timer: any;
    slideOpts = {
        direction: 'horizontal',
        slidesPerView: 1,
        freeMode: true,
        effect: 'coverflow',
        coverflowEffect: {
            width: 50,
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
        }
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoadingFoldersAndFiles: false,
            documents: [],

            showFilePopover: false,
            filePopoverEvent: undefined,
            showDeleteFileAlert: false,
            dragOverDropzone: false,

            ///////////
            userUuid: null,
            addDeviceBannerVisible: false,

            unsubscribeOnFilesChanges: null,

            selectedFileWithContextMenu: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if(userData) {
                this.setState({ userUuid: userData.uid });

                const q = query(collection(firestore, "f"), where("o", "==", userData.uid));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    this.setState({ isLoadingFoldersAndFiles: true });
                    this.setState({
                        documents: filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot),
                        isLoadingFoldersAndFiles: false,
                    });
                });
                this.setState({ unsubscribeOnFilesChanges: unsubscribe });

                if ((isPlatform('cordova') || isPlatform('capacitor'))) {
                    userServices.fetchDevices(userData.uid)
                    .then(devices => {
                        const filteredDevices = devices.filter(device => { return device.deviceId === Device.uuid })
                        if (filteredDevices.length < 1) {
                            this.setState({ addDeviceBannerVisible: true });
                        }
                    })
                    .catch(err => {
                        console.error("[TasksList] fetch devices error:", err); // TO REMOVE
                    });
                }
                
            }
            else {
                this.setState({ userUuid: null });
            }
        })
    }

    componentWillUnmount() {
        if(this.state.unsubscribeOnFilesChanges) {
            this.state.unsubscribeOnFilesChanges();
        }
    }

    uploadFileFromMobile() {
        const chooser = Chooser;
        chooser.getFile('application/pdf') // TO DO: SUPPORT ALSO IMAGES
        .then(file => {
            if (file && file.data && this.state.userUuid) {
                //console.log(`[uploadFileFromMobile] result 3: ${file.mediaType}`);
                var blob = new Blob([file.data], { type: file.mediaType });

                filesServices.uploadFile(blob, this.state.userUuid, file.name)
                .then(() => {
                    console.log('[FileManager uploadFileFromMobile] Upload done');
                    /* window.location.reload(); */
                })
                .catch((e) => {
                    console.error('[FileManager uploadFileFromMobile] Upload failed:', e);
                })
            }
        })
        .catch(err => {
            console.error('[uploadFileFromMobile]', JSON.stringify(err));
        })
    }

    render() {
        const animationOptions = {
            loop: true,
            autoplay: true,
            animationData: tapAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <IonPage>
                <SideMenu history={this.props.history} />
                {/* <IonPage> */}
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            <Trans>Gestore dei File</Trans>
                        </IonTitle>
                        <IonButtons slot="start">
                            <IonMenuButton autoHide={false} menu='sideMenu'></IonMenuButton>
                        </IonButtons>

                        {
                            this.state.isLoadingFoldersAndFiles &&
                            <IonProgressBar
                                type='indeterminate'
                            />
                        }

                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen id='fileManagerContent'>
                    <Dropzone
                        onDragLeave={() => this.setState({ dragOverDropzone: false })}
                        onDragOver={() => this.setState({ dragOverDropzone: true })}
                        noClick={true}
                        onDrop={acceptedFiles => {
                            this.setState({ dragOverDropzone: false })
                            if(this.state.userUuid) {
                                filesServices.uploadFile(acceptedFiles[0], this.state.userUuid)
                                .then(document => {
                                    /* this.state.documents.push(document);
                                    this.setState({
                                        documents: this.state.documents
                                    }); */
                                })
                                .catch(err => {
                                    console.error('[FilesManager] error uploading file from dropzone:', err);
                                })
                            }
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section
                                className={this.state.dragOverDropzone ? 'filesManagerDropzoneActive' : 'filesManagerDropzone'} // last class not defined
                                style={{ height: '100%' }}
                            >
                                <div style={{ height: '100%' }} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    
                                    {
                                        !this.state.isLoadingFoldersAndFiles &&
                                        <IonGrid>
                                            {
                                                this.state.documents.length < 1 &&
                                                <IonRow>
                                                    <p className='filesManagerUploadCTAParagraph'>
                                                        <Trans>Carica il tuo primo documento</Trans>
                                                    </p>

                                                    <div className='filesManagerTapToUploadAnimation'>
                                                        <Lottie
                                                            isClickToPauseDisabled={true}
                                                            options={animationOptions}
                                                            height={200}
                                                            width={200}
                                                        />
                                                    </div>
                                                </IonRow>
                                            }

                                            <div>
                                                {
                                                    this.state.addDeviceBannerVisible &&
                                                    <div className='filesManagerNotificationsDivWiggle'>
                                                        <IonGrid
                                                            class='filesManagerNotificationsAlertGrid'
                                                            onClick={() => {
                                                                PushNotifications.requestPermission().then(result => {
                                                                    if (result.granted) {
                                                                        // Register with Apple or Google to receive push via APNS/FCM
                                                                        PushNotifications.register()
                                                                        .then(() => {
                                                                            console.log("[FilesManager] register push notification devices pressed");
                                                                            this.setState({ addDeviceBannerVisible: false });
                                                                        })
                                                                        .catch(err => {
                                                                            console.error("[FilesManager] error registering device:", err);
                                                                        });
                                                                    }
                                                                    else {
                                                                        console.error(`[pushNotificationHandler - requestPermission] not granted: ${JSON.stringify(result)}`);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.error('[push notification requestPermission] error:', error)
                                                                });
                                                            }}
                                                        >
                                                            <IonRow>
                                                                <IonImg
                                                                    class='filesManagerNotificationsIllustration'
                                                                    src={notificationsImage}
                                                                />
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonText className='filesManagerNotificationsCTAText'>
                                                                    <Trans>Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.</Trans>
                                                                </IonText>
                                                            </IonRow>
                                                        </IonGrid>
                                                    </div>
                                                }
                                            </div>

                                            <IonRow>
                                                {
                                                    this.state.documents
                                                    .map((file) => {
                                                        return (
                                                            <div
                                                                key={file.uuid}
                                                                id={`d${file.uuid}`}
                                                            >
                                                                <IonCard
                                                                    onTouchStart={(e) => {
                                                                        e.preventDefault()
                                                                        this.timer = setTimeout(
                                                                            () => this.setState({
                                                                                showFilePopover: true,
                                                                                filePopoverEvent: e.nativeEvent,
                                                                                selectedFileWithContextMenu: file.uuid
                                                                            }),
                                                                            600
                                                                        )
                                                                    }}
                                                                    onTouchEnd={(e) => clearInterval(this.timer)}
                                                                    onContextMenu={e => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            showFilePopover: true,
                                                                            filePopoverEvent: e.nativeEvent,
                                                                            selectedFileWithContextMenu: file.uuid,
                                                                        });
                                                                    }}
                                                                    key={`d${file.uuid}`}
                                                                    className='fileManagerItem'
                                                                    routerLink={`/file/${file.uuid}`}
                                                                    style={{ width: "140px" }}
                                                                >
                                                                    <IonCardContent>
                                                                        {
                                                                            file.preview &&
                                                                            <IonImg
                                                                                src={file.preview}
                                                                            />
                                                                        }
                                                                        {
                                                                            !file.preview &&
                                                                            <p>
                                                                                <IonImg src={previewSampleImage}></IonImg>
                                                                            </p>
                                                                        }

                                                                    </IonCardContent>
                                                                    <p className="fileManagerFileName">{file.name}</p>
                                                                </IonCard>
                                                                <IonPopover animated={false} showBackdrop={false} event={this.state.filePopoverEvent} isOpen={this.state.showFilePopover} onDidDismiss={() => this.setState({ showFilePopover: false, filePopoverEvent: undefined })}>
                                                                    <IonItem
                                                                        button
                                                                        onClick={() => {
                                                                            this.setState({ showDeleteFileAlert: true });
                                                                        }}
                                                                    >
                                                                        <IonIcon slot="start" icon={trash} />
                                                                        Rimuovi File
                                                                    </IonItem>
                                                                    
                                                                </IonPopover>
                                                                <IonAlert
                                                                    isOpen={this.state.showDeleteFileAlert}
                                                                    onDidDismiss={() => this.setState({ showDeleteFileAlert: false, showFilePopover: false })}
                                                                    header={'Rimuovi documento'} /* TO BE LOCALIZED */
                                                                    message={"Si è sicuri di voler rimuovere il file?"} /* TO BE LOCALIZED */
                                                                    buttons={[
                                                                        {
                                                                            text: "Rimuovi",
                                                                            handler: () => {
                                                                                if(this.state.selectedFileWithContextMenu) {
                                                                                    filesServices.deleteFile(this.state.selectedFileWithContextMenu);
                                                                                }
                                                                            } 
                                                                        }
                                                                    ]}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </IonRow>
                                        </IonGrid>
                                    }
                                </div>
                            </section>
                        )}
                    </Dropzone>

                </IonContent>

                <IonFab
                    horizontal="end"
                    vertical="bottom"
                    slot='fixed'
                    className='filesManagerAddFab'
                    onClick={() => {
                        if ((isPlatform('capacitor') || isPlatform('cordova'))) {
                            this.uploadFileFromMobile();
                        }
                    }}

                >
                    <IonFabButton color="light">
                        <IonIcon icon={add} />
                        {
                            (isPlatform('capacitor') || isPlatform('cordova')) ?
                                (<></>) :
                                (
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
                                            //console.log('FILE SIZE: ', file?.size)
                                            /* if (file!.size > 5242880) {
                                                alert(i18n.language === 'en' ? 'The file size limit is 5MB' : 'Ehi ricordati che puoi caricare file di massimo 5MB') // NEED TRANSLATION IN FR AND ES
                                                return
                                            } */

                                            // TO DO: ERROR ON OVERSIZED DOCUMENTS
                                            if (file && this.state.userUuid) {
                                                filesServices.uploadFile(file, this.state.userUuid)
                                                .then(() => {
                                                    console.log("[FilesManager] upload success");
                                                    /* this.state.documents.push(document);
                                                    this.setState({ documents: this.state.documents });
                                                    this.watchForFileProcessingChanges(document.uuid); */
                                                })
                                                .catch(err => {
                                                    console.error('Error uploading file', err);
                                                    alert("Errore durante il caricamento del file, riprovare");
                                                })
                                            }
                                        }}
                                        accept='application/pdf'
                                    />
                                )
                        }
                    </IonFabButton>
                </IonFab>

                {/* <MenuTabBar />*/}

            </IonPage >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesManager);