
import React from 'react';
import {
    IonButton, IonGrid, IonIcon, IonRow, IonSlide,
}
    from '@ionic/react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import uploadFile from '../../../assets/illustrations/uploadFile.json';
// animazione: https://lottiefiles.com/7877-uploading-to-cloud

import './OnboardingSlide.css';
import { arrowForward } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';

type Props = {
}

type State = {
}

class OnboardingUploadFile extends React.Component<Props, State> {

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: uploadFile,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <IonSlide className='onboardingSlide'>
                <IonGrid className='onboardingSlideGrid'>

                    <IonRow className='onboardingSlideAnimationRow'>
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={defaultOptions}
                            height={isMobile ? 200 : 400}
                            width={isMobile ? 200 : 400}
                        />
                    </IonRow>

                    <IonRow className='onboardingSlideHeaderRow'>
                        <h2 className='onboardingSlideHeader'>
                            <Trans>Carica un documento</Trans>
                        </h2>
                    </IonRow>

                    <IonRow className='onboardingSlideBodyRow'>
                        <p className='onboardingSlideBodyParagraph'>
                            <Trans>Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.</Trans>
                        </p>
                    </IonRow>

                    <IonRow className='onboardingSlideCTAButtonRow'>
                        <IonButton
                            className='onboardingSlideCTAButton'
                            onClick={() => {
                                (document.getElementById('onboardingSlides') as HTMLIonSlidesElement)
                                    .slideNext()
                                    .then(() => { })
                                    .catch(() => { });
                            }}
                            shape='round'
                            fill='outline'
                            color='medium'
                        >
                            Avanti {/* TO LOCALIZE THIS */}
                            <IonIcon
                                icon={arrowForward}
                            />
                        </IonButton>
                    </IonRow>

                </IonGrid>
            </IonSlide>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingUploadFile);