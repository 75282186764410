import { IonButton, IonContent, IonIcon, IonPopover } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { DocumentElement } from '../files/store/reducers';
import { DocumentFontSettings, fromBackendFontNameToCssFontFamily } from '../settings/store/services';
import "./ClickPopover.css";
import DrawsAndImages from './components/clickPopover/DrawsAndImages';
import Dictionary from './components/Dictionary';
import WordActions from './components/WordActions';


type Props = {
    history: any,
    
    isOpen: boolean,
    documentElement: DocumentElement | null,
    fontSettings: DocumentFontSettings | undefined,

    onDidDismiss?: () => void,
}

type State = {
    navigationStack: string[],

    selectedText: string | null,
};


class ClickPopover extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            navigationStack: [],

            selectedText: null,
        }
    }

    navigateBack() {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.pop();
        this.setState({
            navigationStack: navStackCopy,
        });
    }

    navigateTo(path: string) {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.push(path);
        this.setState({
            navigationStack: navStackCopy
        });
    }
    
    dismissPopover() {
        this.setState({
            navigationStack: [],
            selectedText: null,
        });
    }

    render() {
        return (
            <IonPopover
                cssClass="clickPopoverMainClass"
                mode="md"
                isOpen={this.props.isOpen}
                onDidDismiss={() => {
                    if(this.props.onDidDismiss) {
                        this.props.onDidDismiss();
                        this.dismissPopover();
                    }
                }}
            >
                <IonContent>
                    {
                        this.state.navigationStack.length > 0 &&
                        <IonButton
                            className='selectionPopoverNavigateBackDiv'
                            fill="clear"
                            onClick={() => {
                                this.navigateBack();
                            }}
                        >
                            <IonIcon
                                className='selectionPopoverNavigateBackIcon'
                                icon={chevronBack}
                            />
                        </IonButton>
                    }

                    {
                        this.state.navigationStack.length === 0 &&
                        <div
                            className="clickPopoverInnerDiv"
                            style={{fontFamily: this.props.fontSettings ? fromBackendFontNameToCssFontFamily(this.props.fontSettings.font) : ""}}
                        >
                            <p className="clickPopoverHeaderParagraph">
                                Seleziona una parola {/* TO BE LOCALIZED */}
                            </p>
                            <div className="clickPopoverTextDiv">
                                {
                                    this.props.documentElement &&
                                    this.props.documentElement.text.split(" ")
                                    .map((text, i) => { return (
                                        <div
                                            key={i}
                                            className="clickPopoverTextElement"
                                            onClick={() => {
                                                this.setState({
                                                    selectedText: text,
                                                }, () => {
                                                    this.navigateTo("wordActions");
                                                })
                                            }}
                                        >
                                            {text}
                                        </div>
                                    )})
                                }
                            </div>
                        </div>
                    }

                    {
                        (this.state.navigationStack.length > 0) &&
                        this.state.navigationStack[this.state.navigationStack.length - 1] === "wordActions" &&
                        this.state.selectedText &&
                        <>
                            <DrawsAndImages
                                history={this.props.history}
                                searchText={this.state.selectedText}
                            />
                        </>
                    }

                    {
                        (this.state.navigationStack.length > 0) &&
                        this.state.navigationStack[this.state.navigationStack.length - 1] === "wordActions" &&
                        this.state.selectedText &&
                        <div className="clickPopoverWordActionsDiv">
                            <WordActions
                                history={this.props.history}
                                selectedText={this.state.selectedText}
                                showDrawImage={false}
                                onDictionaryClicked={() => {
                                    this.navigateTo("wordDictionary")
                                }}
                            />
                        </div>
                    }

                    {
                        (this.state.navigationStack.length > 0) &&
                        this.state.navigationStack[this.state.navigationStack.length - 1] === "wordDictionary" &&
                        this.state.selectedText &&
                        <div className="clickPopoverWordDictionaryDiv">
                            <Dictionary
                                text={this.state.selectedText}
                            />
                        </div>
                    }
                </IonContent>
            </IonPopover>
        );
    }
}

export default ClickPopover;                   
