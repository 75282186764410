import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
/* import logger from 'redux-logger'; // NOT FOR PRODUCTION */

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Authentication from '../modules/authentication/store/reducers';
import tutorials from '../modules/tutorials/store/reducers';
import createMigrate from 'redux-persist/es/createMigrate';
/* const middlewares = [thunk, logger]; // NOT FOR PRODUCTION */
const middlewares = [thunk];

const rootReducer = combineReducers({
    auth: Authentication,
    tutorials: tutorials,    
});

const migrations = {
    0: (state: any) => {
        return {
            ...state,
            user: null,
            settings: null,
            tts: null,
            app: null,
        }
    },
}

const persistConfig = {
    key: 'root',
    storage,
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(...middlewares));
let persistor = persistStore(store);

export { store, persistor }