import { IonImg } from '@ionic/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React from 'react';
import { fromGSUrltoUrl } from '../../../../common/firebaseUtils';
import { auth, firestore } from '../../../../firebaseConfig';
import { imagesSearch } from '../../common/imagesSearch';
import "./DrawsAndImages.css";

type Props = {
    history: any,
    searchText: string | null,
};

type State = {
    isLoadingDraws: boolean,
    isLoadingImages: boolean,

    drawsUrls: string[],
    imagesUrls: string[],
};


class DrawsAndImages extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoadingDraws: false,
            isLoadingImages: false,

            drawsUrls: [],
            imagesUrls: [],
        }
    }

    componentDidMount() {

        auth.onAuthStateChanged(async userData => {
            if(userData && this.props.searchText) {

                // DRAWS
                this.setState({ isLoadingDraws: true });
                const q = query(collection(firestore, `d/${userData.uid}/d`), where("w", "==", this.props.searchText.trim()));
                try {

                    const drawsUrlsToReturn: string[] = [];
                    const querySnapshot = await getDocs(q);
                    console.log("[WordActions] query snapshot: ", querySnapshot);
    
                    if (!querySnapshot.empty) {
                        for (let i = 0; i < querySnapshot.docs.length; i++) {
                            if(querySnapshot.docs[i].data() && querySnapshot.docs[i].data().p) {
                                drawsUrlsToReturn.push(await fromGSUrltoUrl(querySnapshot.docs[i].data().p));
                            }
                        }
                    }
                    this.setState({
                        isLoadingDraws: false,
                        drawsUrls: drawsUrlsToReturn,
                    });
                }
                catch(e) {
                    console.error("[DrawsAndImages] error querying user draws:", e);
                    this.setState({ isLoadingDraws: false });
                }

                // IMAGES
                try {
                    this.setState({ isLoadingImages: true });
                    this.setState({
                        imagesUrls: (await imagesSearch(this.props.searchText)),
                        isLoadingImages: false,
                    });
                }
                catch(e) {
                    console.error("[DrawsAndImages] error calling imageSearch function:", e);
                    this.setState({ isLoadingImages: false });
                }
            }
        })
    }

    

    render() {
        return (
            <div
            >
                {
                    ((this.state.drawsUrls.length > 0) || (this.state.imagesUrls.length > 0 )) &&
                    <div className="drawsAndImagesInnerDiv">
                        {
                            this.state.drawsUrls.map((url, i) => { return (
                                <IonImg
                                    key={i}
                                    className="drawsAndImagesImg"
                                    src={url}
                                    onClick={() => this.props.history.push({
                                        pathname: '/sketch',
                                        state: { word: this.props.searchText?.trim(), drawing: url }
                                    })}
                                />
                            )})
                        }

                        {
                            this.state.imagesUrls.map((base64data, i) => { return (
                                <IonImg
                                    key={i}
                                    className="drawsAndImagesImg"
                                    src={`data:image/png;base64,${base64data}`}
                                />
                            )})
                        }
                    </div>
                }

                {
                    (this.state.drawsUrls.length === 0) &&
                    (this.state.imagesUrls.length === 0) &&
                    !this.state.isLoadingDraws &&
                    !this.state.isLoadingImages &&
                    <p className="drawAndImagesNoDrawsOrImagesParagraph">
                        Nessun disegno o immagine associata a questa parola. {/* TO BE LOCALIZED */}
                    </p>
                }
            </div>
        );
    }
}

export default DrawsAndImages;