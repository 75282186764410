import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { volumeHigh } from 'ionicons/icons';
import React from 'react';
import { auth } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import { ttsServices } from '../../tts/store/services';
import { getTextMeanings } from '../common/dictionaryServices';
import { getTTS } from '../common/ttsServices';
import './Dictionary.css';

type Props = {
    text: string | null,
};

type State = {
    isLoadingTTS: boolean,

    dictionarySearchResult: any[] | null,
};


class Dictionary extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoadingTTS: false,

            dictionarySearchResult: null,
        }
    }

    componentDidMount() {

        if (this.props.text) {
            this.searchWord(this.props.text)
        }

    }

    componentDidUpdate(prev: Props) {
        if ((prev.text !== this.props.text) && this.props.text) {
            this.searchWord(this.props.text);
        }
    }

    searchWord(text: string) {
        getTextMeanings(text)
            .then(dictionarySearchResult => {
                this.setState({ dictionarySearchResult: dictionarySearchResult })
            })
            .catch(err => {
                //console.log('[Dictionary] error searching the word with uuid', word.uuid, 'in dictionary. Error:', err);
            })
    }

    render() {
        return (
            <div
            /* style={{
                fontFamily: this.state.fontSettings ? settingsServices.fromBackendFontNameToCssFontFamily(this.state.fontSettings.font) : '',
                fontSize: this.state.fontSettings ? this.state.fontSettings.fontSize : '',
            }} */
            >
                <div className='dictionaryWordAndTTSDiv'>
                    <p className='dictionaryWord'>
                        {this.props.text ? this.props.text.toUpperCase() : ""}
                    </p>
                    <IonButton
                        className='dictionaryWordTTSButton'
                        fill='clear'
                        onClick={() => {
                            if (this.props.text) {
                                this.setState({ isLoadingTTS: true });
                                ttsServices.getTTSSettings(auth.currentUser!.uid)
                                    .then(ttsSettings => {
                                        getTTS(this.props.text!.trim(), i18n.language, ttsSettings)
                                            .then((base64Audio) => {
                                                let elemAudio = document.createElement('audio');
                                                document.body.appendChild(elemAudio);
                                                elemAudio.controls = true;
                                                elemAudio.src = base64Audio;
                                                elemAudio.hidden = true;
                                                elemAudio.play();
                                                this.setState({ isLoadingTTS: false });
                                            })
                                            .catch(err => {
                                                console.error("[WordActions] error getting tts:", err);
                                                this.setState({ isLoadingTTS: false });
                                            });
                                    })

                            }
                        }}
                    >
                        {
                            !this.state.isLoadingTTS &&
                            <IonIcon
                                icon={volumeHigh}
                            />
                        }

                        {
                            this.state.isLoadingTTS &&
                            <IonSpinner
                                className="dictionaryLoadingTtsSpinner"
                            />
                        }

                    </IonButton>

                </div>
                <div className='dictionaryMeaningsDiv'>
                    {this.state.dictionarySearchResult && this.state.dictionarySearchResult.length >= 1 &&
                        this.state.dictionarySearchResult.map(result => {
                            return (
                                <p key={this.state.dictionarySearchResult?.indexOf(result)}>{result}</p>
                            )
                        })
                    }
                    {this.state.dictionarySearchResult?.length === 0 &&
                        <p>Non ci sono definizioni</p>
                    }
                </div>
            </div>
        );
    }
}

export default Dictionary;