import apiService from '../../../common/apiService';
import { Document, DocumentElement, SearchWordImagesResult, Sentence } from './reducers';
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { firestore, functions, storage } from '../../../firebaseConfig';
import { collection, doc, DocumentData, getDoc, getDocs, QuerySnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

export const filesServices = {
    getFile,
    fromDocumentDataToDocumentElements,
    getMainFileData,
    getOrderedDocuments,

    uploadFile,
    deleteFile,
    addTagsToDocument,
    getImages,

    fromFilesSnapshotToArrayOfDocuments,
};

function fromFilesSnapshotToArrayOfDocuments(querySnapshot: QuerySnapshot): Document[] {
    const documentsToReturn: Document[] = [];
    for (let i = 0; i < querySnapshot.docs.length; i++) {
        documentsToReturn.push(fromDocumentDataToDocument(querySnapshot.docs[i]));
    }
    return documentsToReturn;
}

function fromDocumentDataToDocument(documentData: DocumentData, documentParagraphs?: DocumentData): Document {
    return {
        uuid: documentData.ref.id,
        created_at: documentData.data().c as number,
        last_access: documentData.data().e as number,
        name: documentData.data().t as string,
        preview: "",
        sentences: documentParagraphs ? fromDocumentParagraphsToArrayOfSentences(documentParagraphs) : undefined,
    }
}

function fromDocumentParagraphsToArrayOfSentences(paragraphsObj: DocumentData): Sentence[] {
    const sentencesToReturn: Sentence[] = [];
    for (let i = 0; i < paragraphsObj.docs.length; i++) {
        sentencesToReturn.push({
            uuid: paragraphsObj.docs[i].ref.id,
            index: paragraphsObj.docs[i].data().i,
            text: paragraphsObj.docs[i].data().p,
            language: "it",
            sentence_words: [],
        })
    }
    sentencesToReturn.sort((a, b) => a.index - b.index)
    return sentencesToReturn;
}

function getMainFileData(fileId: string) {
    return new Promise<Document>((resolve, reject) => {
        getDoc(doc(firestore, `f/${fileId}`))
            .then(async documentSnapshot => {
                resolve(fromDocumentDataToDocument(documentSnapshot));
            })
            .catch(err => {
                console.error("[Files services] getting file:", err);
                reject(err);
            })
    })
}

function getFile(fileId: string) {
    return new Promise<Document>((resolve, reject) => {
        getDoc(doc(firestore, `f/${fileId}`))
            .then(async documentSnapshot => {
                let documentParagraphsData: DocumentData | undefined = undefined;
                try {
                    documentParagraphsData = (await getDocs(collection(firestore, `f/${fileId}/d`)));
                }
                catch (e) {
                    console.error("[files services - get file] error getting file doc data:", e);
                }
                resolve(fromDocumentDataToDocument(documentSnapshot, documentParagraphsData));
            })
            .catch(err => {
                console.error("[Files services] getting file:", err);
                reject(err);
            })
    })
}

function fromDocumentDataToDocumentElements(data: DocumentData): DocumentElement[] {
    const documentElements: DocumentElement[] = [];
    for (let i = 0; i < data.docs.length; i++) {
        documentElements.push({
            uuid: data.docs[i].ref.id,
            documentId: data.docs[i].data().d,
            index: data.docs[i].data().i,
            text: data.docs[i].data().p,
        });
    }
    documentElements.sort((a, b) => a.index - b.index);
    return documentElements;
}

function uploadFile(file: File | Blob, userUuid: string, chooserFileName?: string) {
    return new Promise((resolve, reject) => {
        let fileName: string | null = null;
        if (file instanceof File) {
            console.log("FILE NAME", file.name); // TO REMOVE
            console.log("extension:", (file as File).name.split(".").pop()); // TO REMOVE
            fileName = file.name.trim();
        }
        else if ((file instanceof Blob) && chooserFileName) {
            fileName = chooserFileName;
        }

        uploadBytes(storageRef(storage, `f/${userUuid}/${makeId(16)}${fileName ? `/${fileName}` : ""}`), file)
            .then(data => {
                console.log("[File services] upload success:", data.ref);
                resolve(data.ref);

                /* fromGSUrltoUrl(data.ref.fullPath)
                    .then(url => {
                        this.setState({ imgUrl: url })
                    })
                    .catch(err => {
                        console.error("[File services] error getting public url:");
                        
                    }) */
            })
            .catch(err => {
                console.log("[Files services] error uploading file", err);
                reject(err);
            })
    })
}

function deleteFile(fileUuid: string) {
    return new Promise((resolve, reject) => {
        const deleteFileFunction = httpsCallable(functions, "deleteFile-deleteFile");
        deleteFileFunction({
            fileUuid: fileUuid,
        })
            .then(res => {
                if (!(res.data as any).error) {
                    // TO DO: edit this
                    resolve(fileUuid);
                }
                else {
                    console.error("[File services] error returned by deleFile cloud function:", (res.data as any).error);
                    reject((res.data as any).error);
                }
            })
            .catch(err => {
                console.error("[File services] error deleting file:", err);
                reject(err);
            });
    })
}


function getOrderedDocuments(ordering: 'ascending' | 'descending' = 'descending') {
    return new Promise<Document[]>((resolve, reject) => {
        apiService.get(`documents/?ordering=${ordering === 'descending' ? '-' : ''}created_at`)
            .then(response => {
                //console.log('[getOrderedDocuments] response:', response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[getOrderedDocuments] error:', error.response.data);
                reject(error.response.data);
            });
    })
}

function addTagsToDocument(documentUuid: string, tags: string[]) {
    return new Promise((resolve, reject) => {
        apiService.patch(`documents/${documentUuid}/`, {
            tags: tags,
        })
            .then(response => {
                //console.log('[addTagsToDocument] response:', response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[addTagsToDocument] error:', error.response.data);
                reject(error.response.data);
            });
    })
}

function getImages(wordUuid: string) {
    return new Promise<SearchWordImagesResult>((resolve, reject) => {
        // search if that word was already searched
        apiService.get(`word-image-search/?word__uuid=${wordUuid}`)
            .then(response0 => {
                //console.log('[getImage] response:', response0.data);
                if (response0.data && (response0.data.length > 0)) {
                    resolve(response0.data[0])
                }
                else {
                    apiService.post(`word-image-search/`, {
                        word__uuid: wordUuid,
                    })
                        .then(response1 => {
                            //console.log('[getImage] response to the word-image conversion:', response1.data); // for debugging
                            resolve(response1.data[0]);
                        })
                        .catch(err1 => {
                            //console.log('[getImage] error converting image:', err1);
                            reject(err1.response.data);
                        });
                }
            })
            // try to convert the image anyway
            .catch(err0 => {
                //console.log('[getImage] error searching word-image associations:', err0);
                apiService.post(`word-image-search/`, {
                    word__uuid: wordUuid,
                })
                    .then(response2 => {
                        //console.log(`[getImage] response: ${JSON.stringify(response2.data)}`); // for debugging
                        resolve(response2.data[0]);
                    })
                    .catch(err2 => {
                        //console.log('[getImage] error converting image:', err2);
                        reject(err2.response.data);
                    });
            })
    })
}

export function makeId(length: number): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}