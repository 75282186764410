import {
	IonBackButton, IonButton, IonButtons, IonContent,
	IonGrid, IonHeader, IonInput, IonPage, IonTitle,
	IonToggle, IonToolbar
} from '@ionic/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { validateEmail } from '../../../common/validations';
import { sendSignInLinkToEmail } from "firebase/auth";
import { auth } from '../../../firebaseConfig';
import "./MagicLinkRequest.css";
import magicLinkAnimation from '../../../assets/animations/magic-link.json'

type Props = {
	history: any,
	isAuthenticated: boolean,
}

type State = {
	policyChecked: boolean,
	email: string | null | undefined,
	isValidEmail: boolean,
	showConfirmationDiv: boolean
}

class MagicLinkRequest extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			policyChecked: false,
			email: '',
			isValidEmail: false,
			showConfirmationDiv: false,
		}
	}

	render() {
		const animationOptions = {
			loop: true,
			autoplay: true,
			animationData: magicLinkAnimation,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice"
			}
		};
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/authentication/get-started" />
						</IonButtons>
						<IonTitle>Link magico</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonContent fullscreen>

					<IonGrid className="magicLinkRequestMainGrid">
												
						{
							!this.state.showConfirmationDiv &&
							<div className="magicLinkRequestMainTextDiv">
								<p className="magicLinkRequestHintParagraph">
									Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella che ti permetterà di accedere a Reasy in un attimo. {/* TO BE LOCALIZED */}
								</p>
							</div>
						}

						{
							!this.state.showConfirmationDiv &&
							<div className="magicLinkRequestEmailAndTermsDiv">
								<div>
									<IonInput
										className="magicLinkRequestEmailInput"
										type='email'
										value={this.state.email}
										autocomplete='email'
										inputMode='email'
										placeholder="La tua email" /* TO BE LOCALIZED */
										onIonChange={(e) => {
											if (e.detail.value) {
												this.setState({
													email: e.detail.value,
													isValidEmail: validateEmail(e.detail.value),
												})
											}
											else {
												this.setState({
													email: e.detail.value,
													isValidEmail: false
												});
											}
										}}
									/>

									<div className="magicLinkRequestToggleAndTermsDiv">
										<IonToggle
											mode="md"
											className="magicLinkRequestToggle"
											onIonChange={e => {
												this.setState({ policyChecked: e.detail.checked })
											}}
										/>

										<p className="magicLinkRequestTermsParagraph">
											<Trans>Ho letto e accetto la</Trans>&nbsp;
											<a href='/legal/privacy-policy'>privacy policy</a><Trans> e i</Trans>&nbsp;
											<a href='/legal/terms-conditions'><Trans>termini di servizio</Trans></a>.
										</p>
									</div>
								</div>
							</div>
						}

						{
							!this.state.showConfirmationDiv &&
							<div className="magicLinkRequestSendEmailDiv">
								<IonButton
									className="magicLinkRequestSendEmailButton"
									expand="block"
									onClick={() => {
										if (this.state.email) {
											const actionCodeSettings = {
												url: 'https://reading-easy.it/authentication/completion', // FOR PRODUCTION
												handleCodeInApp: true,
												iOS: {
													bundleId: "ai.estro.reasy",
												},
												android: {
													packageName: "ai.estro.reasy",
													installApp: true,
													minimumVersion: '12'
												},
												dynamicLinkDomain: 'readingeasy.page.link'
											};

											sendSignInLinkToEmail(auth, this.state.email, actionCodeSettings)
												.then(() => {
													window.localStorage.setItem('emailForSignIn', String(this.state.email));
													console.log("[MagicLinkRequest] link sent.");
													this.setState({ showConfirmationDiv: true });
												})
												.catch(e => {
													console.error("[MagicLinkRequest] send signin link error:", e);
												});
											//let the user know what to do:
											//e.g. "we've sent a verification link to '[email]', check your email inbox"
										}
									}}

									disabled={
										!this.state.email ||
										!this.state.isValidEmail ||
										!this.state.policyChecked ||
										this.state.showConfirmationDiv
									}
								>
									<Trans>Iniziamo</Trans>
								</IonButton>
							</div>
						}

						{
							this.state.showConfirmationDiv &&
							<div className="magicLinkRequestConfirmationDiv">
								<p className="magicLinkRequestConfirmationParagraph">
									Abbiamo inviato il link alla email da te inserita: <i>{this.state.email ? this.state.email.trim() : ""}</i> ✅ {/* TO BE LOCALIZED */}
								</p>
								<p className="magicLinkRequestConfirmationCTAParagraph">
									Vai ad aprire la tua casella email!
								</p>
							</div>
						}

					</IonGrid>


					{this.props.isAuthenticated && <Redirect to="/" />}
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.access ? true : false,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicLinkRequest);