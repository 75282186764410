
import React from 'react';
import {
    IonButton, IonGrid, IonRow, IonSlide,
}
    from '@ionic/react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import settings from '../../../assets/illustrations/settings.json';
// animazione: https://lottiefiles.com/4749-phone-settings

import './OnboardingSlide.css';
import { tutorialsActions } from '../../tutorials/store/actions';
import { Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';

type Props = {
    history: any,

    hideOnboarding: () => void,
}

type State = {
}

class OnboardingSettings extends React.Component<Props, State> {

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: settings,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <IonSlide className='onboardingSlide'>
                <IonGrid className='onboardingSlideGrid'>

                    <IonRow className='onboardingSlideAnimationRow'>
                        <Lottie
                            isClickToPauseDisabled={true}
                            options={defaultOptions}
                            height={isMobile ? 200 : 400}
                            width={isMobile ? 200 : 400}
                        />
                    </IonRow>

                    <IonRow className='onboardingSlideHeaderRow'>
                        <h2 className='onboardingSlideHeader'>
                            <Trans>Configurazioni</Trans>
                        </h2>
                    </IonRow>

                    <IonRow className='onboardingSlideBodyRow'>
                        <p className='onboardingSlideBodyParagraph'>
                            <Trans>Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile</Trans>
                        </p>
                    </IonRow>

                    <IonRow className='onboardingSlideCTAButtonRow'>
                        <IonButton
                            className='onboardingSlideCTAButton'
                            onClick={() => {
                                this.props.history.replace('/signup')
                                this.props.hideOnboarding();
                            }}
                            shape='round'
                        >
                            Inizia subito {/* TO LOCALIZE THIS */}
                        </IonButton>
                    </IonRow>

                </IonGrid>
            </IonSlide>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        hideOnboarding: () => {
            dispatch(tutorialsActions.showOnboarding(false));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSettings);