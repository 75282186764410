export const SketchTranslationsEN = {
    "Disegna un'immagine per la parola '": "Draw an image for word '",
    "Disegna un' immagine": "Draw an image",
    "Salva": "Save",
    "Indietro": "Undo",
    "Avanti": "Redo",
    "Pulisci": "Clear",
    "Disegna": "Draw",
    "Cancella": "Erase",
}
export const SketchTranslationsIT = {
    "Disegna un'immagine per la parola '": "Disegna un'immagine per la parola '",
    "Disegna un' immagine": "Disegna un' immagine",
    "Salva": "Salva",
    "Indietro": "Indietro",
    "Avanti": "Avanti",
    "Pulisci": "Pulisci",
    "Disegna": "Disegna",
    "Cancella": "Cancella",
}
export const SketchTranslationsES = {
    "Disegna un'immagine per la parola": "Draw an image for word",
    "Salva": "Save",
    "Indietro": "Undo",
    "Avanti": "Redo",
    "Pulisci": "Clear",
    "Disegna": "Draw",
    "Cancella": "Erase",
}
export const SketchTranslationsFR = {
    "Disegna un'immagine per la parola": "Draw an image for word",
    "Salva": "Save",
    "Indietro": "Undo",
    "Avanti": "Redo",
    "Pulisci": "Clear",
    "Disegna": "Draw",
    "Cancella": "Erase",
}