import {
	IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
	IonCardHeader, IonCardTitle, IonContent, IonGrid, IonHeader,
	IonIcon, IonInput, IonItem, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToggle, IonToolbar
} from '@ionic/react';
import { ref, update } from 'firebase/database';
import { call, logoAndroid, logoApple, logoWhatsapp, mail } from 'ionicons/icons';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { auth, database } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import { authActions } from '../../authentication/store/actions';
import { Device } from '../store/reducers';
import { userServices } from '../store/services';
import './Profile.css';

type Props = {
	history: any,
	
	logout: () => void,
}

type State = {
	userUuid: string | null,
	userEmail: string | null,

	userDevices: Device[],
}

class Profile extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			userUuid: null,

			userEmail: null,

			userDevices: [],
		}
	}

	componentDidMount() {
		auth.onAuthStateChanged(userData => {
			if(userData) {
				this.setState({ userUuid: userData.uid });
				if(userData.email) {
					this.setState({ userEmail: userData.email });
				}

				userServices.fetchDevices(userData.uid)
				.then(devices => {
					//console.log("[UserSettings] get devices response:", devices)
					this.setState({ userDevices: devices });
				})
				.catch(err => {
					console.error("[Profile] get devices error:", err)
				});
			}
			else {
				this.setState({
					userUuid: null,
					userEmail: null
				});
			}
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref='/' />
						</IonButtons>
						{/* <IonButtons slot='start'>
								<IonMenuButton/>
						</IonButtons> */}

						<IonTitle><Trans>Profilo</Trans></IonTitle>
					</IonToolbar>
				</IonHeader>


				<IonContent fullscreen>
					<IonGrid className='profileGrid'>
						{
							this.state.userEmail &&
							<IonCard>
								<IonCardHeader>
									<IonCardTitle>
										<Trans>Informazioni</Trans>
									</IonCardTitle>
								</IonCardHeader>

								<IonCardContent>
									<div className="profileUserDataDiv">
										<div className="profileUserDataRow">
												<p><b><Trans>Email</Trans></b></p>
												<p>{this.state.userEmail}</p>
										</div>
									</div>

								</IonCardContent>
							</IonCard>
						}

						{
							this.state.userUuid &&
							<IonCard>
								<IonCardHeader>
									<IonCardTitle>
										<Trans>Lingua dell'applicazione</Trans> {/* TO LOCALIZE THIS */}
									</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<IonList
										lines='none'
									>
										<IonItem>
											<div className='settingsDocumentViewingItemDiv'>
												<IonSelect
													className='settingsDocumentViewingFontPicker'
													value={i18n.language}
													interface='popover'
													onIonChange={e => {
														i18n.changeLanguage(e.detail.value);
														if(this.state.userUuid) {
															update(ref(database, `u/${this.state.userUuid}`), {
																l: e.detail.value,
															})
															.then(() => {
																window.location.reload();
															})
															.catch(err => {
																console.error("[Profile] error updating user language:", err);
															});
														}
													}}
												>
													<IonSelectOption value="it">Italiano</IonSelectOption>
													<IonSelectOption value="en">English</IonSelectOption>
													<IonSelectOption value="fr">Français</IonSelectOption>
													<IonSelectOption value="es">Español</IonSelectOption>
												</IonSelect>
											</div>
										</IonItem>
									</IonList>
								</IonCardContent>
							</IonCard>
						}

						{
                            this.state.userDevices.length > 0 &&
                            <IonCard className="manageDevices">
                                <IonCardHeader>
                                    <IonCardTitle>Dispositivi registrati</IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    {
                                        this.state.userDevices.map(device => {
                                            return (
                                                <div
                                                    key={device.uuid}
                                                    className="userSettingsDeviceDiv"
                                                >
                                                    <IonInput
                                                        value={device.name}
                                                        placeholder={"Nome del dispositivo"} /* TO LOCALIZE THIS */
                                                        onIonChange={e => {
                                                            userServices.editDeviceName(device.uuid, e.detail.value ? e.detail.value : "")
                                                                .then(() => {
																	if(this.state.userUuid) {
																		userServices.fetchDevices(this.state.userUuid)
																		.then(devices => {
																			//console.log("[UserSettings] get devices response:", devices)
																			this.setState({ userDevices: devices });
																		})
																		.catch(err => {
																			console.error("[UserSettings] get devices error:", err)
																		})
																	}
                                                                })
                                                                .catch(err => {
                                                                    //console.log("[UserSetting] editing device status error:", err);
                                                                })
                                                        }}
                                                        debounce={1000}
                                                    />

                                                    {
                                                        device.type === 1 && // ios
                                                        <div className="userSettingsNotificationDeviceOsDiv">
                                                            <IonIcon
                                                                slot="end"
                                                                icon={logoApple}
                                                                className="userSettingsNotificationDeviceOsIcon"
                                                            />
                                                        </div>

                                                    }

                                                    {
                                                        device.type === 2 && // android
                                                        <div className="userSettingsNotificationDeviceOsDiv">
                                                            <IonIcon
                                                                slot="end"
                                                                icon={logoAndroid}
                                                                className="userSettingsNotificationDeviceOsIcon"
                                                            />
                                                        </div>
                                                    }


                                                    <div
                                                        className="userSettingsDeviceActiveToggleDiv"
                                                        slot="end"
                                                    >
                                                        <IonToggle
                                                            checked={device.isActive}
                                                            onIonChange={e => {
                                                                userServices.editDeviceStatus(device.uuid, e.detail.checked)
                                                                    .then(() => {
																		if(this.state.userUuid) {
                                                                        	userServices.fetchDevices(this.state.userUuid)
                                                                            .then(devices => {
                                                                                //console.log("[UserSettings] get devices response:", devices)
                                                                                this.setState({ userDevices: devices });
                                                                            })
                                                                            .catch(err => {
                                                                                console.error("[UserSettings] get devices error:", err);
                                                                            });
																		}
                                                                    })
                                                                    .catch(err => {
                                                                        //console.log("[UserSetting] editing device status error:", err);
                                                                    })
                                                            }}
                                                        />
                                                        <p className="userSettingsDeviceActiveToggleLabel">
                                                            Notifiche push
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </IonCardContent>
                            </IonCard>
                        }

						<IonCard>
							<IonCardHeader>
								<IonCardTitle>
									<Trans>Assistenza</Trans>
								</IonCardTitle>
							</IonCardHeader>

							<IonCardContent>
								<IonGrid className='profileSupportGrid'>
									<IonRow
										onClick={() => { window.open('tel:+393661126799') }}
										className='profileSupportRow'
									>
										<IonIcon
											icon={call}
											size='large'
											className='profileSupportIcon'
										/>
										<IonText className='profileSupportLabel'>
											<Trans>Telefono</Trans>
										</IonText>
									</IonRow>

									<IonRow
										onClick={() => { window.open('mailto:support@tech4all.ai'); }}
										className='profileSupportRow'
									>
										<IonIcon
											icon={mail}
											size='large'
											className='profileSupportIcon'
										/>
										<IonText className='profileSupportLabel'>
											Email
											</IonText>
									</IonRow>

									<IonRow
										onClick={() => { window.open('https://wa.me/393661126799'); }}
										className='profileSupportRow'
									>
										<IonIcon
											icon={logoWhatsapp}
											size='large'
											className='profileSupportIcon'
										/>
										<IonText className='profileSupportLabel'>
											WhatsApp
											</IonText>
									</IonRow>
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</IonGrid>

					<div className='profileLogoutDiv'>
						<IonButton
							fill='outline'
							onClick={() => {
								this.props.logout();
								setTimeout(() => {
									this.props.history.replace('/authentication/get-started');
								}, 700);
							}}
							color='medium'
						>
							<Trans>Logout</Trans>
						</IonButton>
					</div>

				</IonContent>

				

				{/* <div className='profileLogoutDiv'>
					<IonButton
						fill='clear'
						onClick={() => {
							this.deleteAccount();
							setTimeout(() => {
								this.props.logout();
							}, 700)
						}}
						color='medium'

					>
						<Trans>Elimina Account</Trans>

					</IonButton>
				</div> */}

			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () => {
			dispatch(authActions.logout());
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);