export const ProfileTranslationsEN = {
    "Profilo": "Profile",
    "Email":"Email address",
    "Informazioni":"Information",
    "Cambio password":"Password Reset",
    "Assistenza":"Support",
    "Telefono":"Phone",
    "Logout":"Logout",
    "Elimina Account":"Delete Account",

}
export const ProfileTranslationsIT = {
    "Profilo": "Profilo",
    "Informazioni":"Informazioni",
    "Cambio password":"Cambio Password",
    "Assistenza":"Assistenza",
    "Telefono":"Telefono",
    "Logout":"Esci",
    "Elimina Account":"Elimina Account",
}
export const ProfileTranslationsES = {
    "Profilo": "Profilo",
    "Informazioni":"Informazioni",
    "Cambio password":"Cambio Password",
    "Assistenza":"Assistenza",
    "Telefono":"Telefono",
    "Logout":"Esci",
    "Elimina Account":"Elimina Account",
}
export const ProfileTranslationsFR = {
    "Profilo": "Profilo",
    "Informazioni":"Informazioni",
    "Cambio password":"Cambio Password",
    "Assistenza":"Assistenza",
    "Telefono":"Telefono",
    "Logout":"Esci",
    "Elimina Account":"Elimina Account",
}