export const OnboardingTranslationsEN = {
    "Ti diamo il benvenuto su Reasy": "Welcome on Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, your must-have tool",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Upload a pdf and Reasy will turn it into digital. You won't need to transcribe it",
    "Lo strumento per semplificare lo studio" : "The tool to simplify the study",
    "Avanti" : "Continue",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':"In section 'Accessibility' you will find preferences to make text more understandable",
    "Carica un documento":"Upload a file",
    "Configurazioni" : "Setup",
    "Inizia subito" : "Get started",
}
export const OnboardingTranslationsIT = {
    "Ti diamo il benvenuto su Reasy": "Ti diamo il benvenuto su Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, l’unico strumento di cui avrai bisogno",
    "Avanti" : "Avanti",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.",
    "Lo strumento per semplificare lo studio" : "Lo strumento per semplificare lo studio",
    "Carica un documento":"Carica un documento",
    "Configurazioni" : "Configurazioni",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile',
    "Inizia subito" : "Inizia subito",
}
export const OnboardingTranslationsES = {
    "Ti diamo il benvenuto su Reasy": "Ti diamo il benvenuto su Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, l’unico strumento di cui avrai bisogno",
    "Avanti" : "Avanti",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.",
    "Lo strumento per semplificare lo studio" : "Lo strumento per semplificare lo studio",
    "Carica un documento":"Carica un documento",
    "Configurazioni" : "Configurazioni",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile',
    "Inizia subito" : "Inizia subito",
}
export const OnboardingTranslationsFR = {
    "Ti diamo il benvenuto su Reasy": "Ti diamo il benvenuto su Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, l’unico strumento di cui avrai bisogno",
    "Avanti" : "Avanti",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.",
    "Lo strumento per semplificare lo studio" : "Lo strumento per semplificare lo studio",
    "Carica un documento":"Carica un documento",
    "Configurazioni" : "Configurazioni",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile',
    "Inizia subito" : "Inizia subito",
}