import { Plugins } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import { isSignInWithEmailLink, parseActionCodeURL, signInWithEmailLink } from "firebase/auth";
import { auth } from "../firebaseConfig";

const { CapacitorFirebaseDynamicLinks } = Plugins;

function magicLinkHandler() {
    if (isPlatform('capacitor') || isPlatform('cordova')) {
        CapacitorFirebaseDynamicLinks.addListener('deepLinkOpen', (data: { url: string }) => {
            console.log("[magicLinkHandler] dynamic link:", JSON.stringify(data));

            console.log("isSignInWithEmailLink?", isSignInWithEmailLink(auth, data.url)); // TO REMOVE
            const email = window.localStorage.getItem('emailForSignIn');

            if (isSignInWithEmailLink(auth, data.url) && email) {

                console.log("emailForSignIn", email); // TO REMOVE

                console.log("parseActionCodeURL(data.url)", JSON.stringify(parseActionCodeURL(data.url))); // TO REMOVE


                signInWithEmailLink(auth, email, data.url)
                    .then((result) => {
                        console.log("[magicLinkHandler] signin result:", result); // TO REMOVE

                        window.localStorage.removeItem('emailForSignIn');
                        window.location.replace("/files/my-files");
                    })
                    .catch((error) => {
                        console.error("[magicLinkHandler] error signin in wih email link", error.code, error.message);
                    });
            }
            else {

                // TO DO: protect urls redirects

                console.log("[magicLinkHandler] standard url");
                let url = new URL(data.url);

                if (url && url.toString().startsWith('https://reading-easy.it')) {
                    window.location.replace(url.pathname);
                }
                else {
                    window.open(url.toString(), '_system');
                }

                //console.log("[magicLinkHandler] url path name:", url.pathname);
                //window.location.replace(url.pathname);  // TO EDIT
            }
        })
    }
}

export default magicLinkHandler;
