import axios from "axios";
import { TTSSettings } from "../../tts/store/reducers";

export function getTTS(text: string, language: string, settings: TTSSettings) {
    return new Promise<string>((resolve, reject) => {
        let ttsSettings = {
            prosody_rate: settings.prosodyRate,
            voice_gender: settings.voiceType,
        }
        axios.post(`https://fujjvnytml.execute-api.eu-central-1.amazonaws.com/staging/text-to-speech/`, { text: text, language: language, settings: ttsSettings })
            .then(response => {
                /* console.log("[getTTS] response:", response.data); */
                if (response.data.body) {
                    resolve(`data:audio/mpeg;base64,${response.data.body}`);
                }
                reject(response.data);
            })
            .catch(error => {
                console.log('[getTTS] error:', error.response.data);
                reject(error.response.data);
            });
    })
}