export const SettingsTranslationsEN = {
    "Lingua dell'applicazione": "App Language",
    "Evidenziazioni": "Highlightings",
    "Lettere da evidenziare": "Characters to highlight",
    "Salva": "Save",
    "Impostazioni": "Settings",
    "Impostazioni font": "Font settings",
    "Dimensione font": "Font size",
    "Interlinea": "Line spacing",
    "Anteprima": "Preview",
    "Text To Speech": "Text To Speech",
    "Velocità di riproduzione": "Playback speed",
    "Lento": "Slow",
    "Medio": "Medium",
    "Veloce": "Fast",
    "Genere": "Voice type",
    "Maschio": "Voice 1",
    "Femmina": "Voice 2",
    "Player TTS": "TTS Player",
    "Prova la voce": "Try out",
    "Mostra": "Show",
    "Nascondi": "Hide",
    "Licenze": "Licenses",
}
export const SettingsTranslationsIT = {
    "Lingua dell'applicazione": "Lingua dell'applicazione",
    "Evidenziazioni": "Evidenziazioni",
    "Lettere da evidenziare": "Lettere da evidenziare",
    "Salva": "Salva",
    "Impostazioni": "Impostazioni",
    "Impostazioni font": "Impostazioni font",
    "Dimensione font": "Dimensione font",
    "Interlinea": "Interlinea",
    "Anteprima": "Anteprima",
    "Text To Speech": "Text To Speech", //(da cambiare?)
    "Velocità di riproduzione": "Velocità di riproduzione",
    "Lento": "Lento",
    "Medio": "Medio",
    "Veloce": "Veloce",
    "Genere": "Tipologia voce",
    "Maschio": "Voce 1",
    "Femmina": "Voce 2",
    "Player TTS": "Player TTS", //(da cambiare?)
    "Prova la voce": "Prova la voce",
    "Mostra": "Mostra",
    "Nascondi": "Nascondi",
    "Licenze": "Licenze",
}

//TO TRANSLATE!
export const SettingsTranslationsES = {
    "Lingua dell'applicazione": "Lingua dell'applicazione",
    "Evidenziazioni": "Evidenziazioni",
    "Lettere da evidenziare": "Lettere da evidenziare",
    "Salva": "Salva",
    "Impostazioni": "Impostazioni",
    "Impostazioni font": "Impostazioni font",
    "Dimensione font": "Dimensione font",
    "Interlinea": "Interlinea",
    "Anteprima": "Anteprima",
    "Text To Speech": "Text To Speech", //(da cambiare?)
    "Velocità di riproduzione": "Velocità di riproduzione",
    "Lento": "Lento",
    "Medio": "Medio",
    "Veloce": "Veloce",
    "Genere": "Tipologia voce",
    "Maschio": "Voce 1",
    "Femmina": "Voce 2",
    "Player TTS": "Player TTS", //(da cambiare?)
    "Prova la voce": "Prova la voce",
    "Mostra": "Mostra",
    "Nascondi": "Nascondi",
    "Licenze": "Licenze",
}

//TO TRANSLATE!
export const SettingsTranslationsFR = {
    "Lingua dell'applicazione": "Lingua dell'applicazione",
    "Evidenziazioni": "Evidenziazioni",
    "lettere da evidenziare": "Lettere da evidenziare",
    "Salva": "Salva",
    "Impostazioni": "Impostazioni",
    "Impostazioni font": "Impostazioni font",
    "Dimensione font": "Dimensione font",
    "Interlinea": "Interlinea",
    "Anteprima": "Anteprima",
    "Text To Speech": "Text To Speech", //(da cambiare?)
    "Velocità di riproduzione": "Velocità di riproduzione",
    "Lento": "Lento",
    "Medio": "Medio",
    "Veloce": "Veloce",
    "Genere": "Tipologia voce",
    "Maschio": "Voce 1",
    "Femmina": "Voce 2",
    "Player TTS": "Player TTS", //(da cambiare?)
    "Prova la voce": "Prova la voce",
    "Mostra": "Mostra",
    "Nascondi": "Nascondi",
    "Licenze": "Licenze",
}