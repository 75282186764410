import {SettingsTranslationsIT} from '../../modules/settings/SettingsTranslations'
import {ProfileTranslationsIT} from '../../modules/user/ProfileTranslations'
import { MenuTranslationsIT } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsIT } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsIT } from '../../modules/files/FileTranslations';
import { PopoverTranslationsIT } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsIT } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsIT } from '../../modules/onboarding/OnboardingTranslations';

const mergedObject = {
  ...SettingsTranslationsIT,
  ...ProfileTranslationsIT,
  ...MenuTranslationsIT,
  ...SketchTranslationsIT,
  ...FileTranslationsIT,
  ...PopoverTranslationsIT,
  ...AuthTranslationsIT,
  ...OnboardingTranslationsIT,
  };
  
export const it = {
    mergedObject,
}