import {
    IonButton, IonCol, IonContent, IonGrid, IonHeader,
    IonIcon, IonImg, IonItem, IonList, IonMenu, IonMenuToggle,
    IonRow, IonTitle, IonToolbar
} from "@ionic/react";
import { fileTrayFullOutline, logOutOutline, personOutline, settingsOutline } from "ionicons/icons";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { auth } from "../../firebaseConfig";
import { authActions } from "../authentication/store/actions";
import euLogo from '../../assets/images/eulogo.jpg'

type Props = {
    history: any,

    logout: () => void,
}

type State = {
    userEmail: string | null,
}

class SideMenu extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            userEmail: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                if (userData.email) {
                    this.setState({ userEmail: userData.email });
                }
            }
            else {
                this.setState({ userEmail: null });
            }
        });
    }

    render() {
        return (
            <IonMenu
                /* style={this.isPortrait() ? { maxWidth: window.innerWidth } : { maxWidth: "300px" }} */
                type='overlay'
                menuId='sideMenu'
                contentId='fileManagerContent'
            >
                <IonContent>
                    <IonList style={{ paddingTop: '0px' }}>
                        <IonHeader mode='md'>
                            <IonToolbar>
                                <IonTitle>{this.state.userEmail}</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonMenuToggle autoHide={false}>
                            <IonItem style={{ fontSize: '20px' }} routerLink="/files">
                                <IonIcon
                                    size='large'
                                    style={{ margin: '10px' }}
                                    icon={fileTrayFullOutline}
                                />
                                <Trans>Gestione File</Trans>
                            </IonItem>

                            <IonItem style={{ fontSize: '20px' }} routerLink="/settings" >
                                <IonIcon
                                    size='large'
                                    style={{ margin: '10px' }}
                                    icon={settingsOutline}
                                />
                                <Trans>Impostazioni</Trans>
                            </IonItem>

                            <IonItem style={{ fontSize: '20px' }} routerLink="/profile" >
                                <IonIcon
                                    size='large'
                                    style={{ margin: '10px' }}
                                    icon={personOutline}
                                />
                                <Trans>Profilo</Trans>
                            </IonItem>
                        </IonMenuToggle>
                    </IonList>
                </IonContent>

                <IonContent>
                    <IonGrid style={{ justifyContent: 'center', display: 'flex' }}>
                        <IonButton
                            fill='outline'
                            onClick={() => {
                                this.props.logout();
                                setTimeout(() => {
                                    this.props.history.replace('/login');
                                }, 700);
                            }}
                            color='medium'
                        >
                            <IonIcon
                                icon={logOutOutline}
                            />
                            <Trans>Esci</Trans>
                        </IonButton>
                    </IonGrid>
                </IonContent>
                <IonRow style={{ padding: '10px', display: 'flex', flexDirection: 'flex-start', alignItems: 'center' }}>
                    <IonImg style={{ width: '150px ' }} src={euLogo} />
                    <p style={{ fontSize: '8px ' }}>
                        VRAIlexia - Partnering Outside the Box: Digital and Artificial Intelligence Integrated Tools to Support Higher Education Students with Dyslexia.Erasmus+ Programme2014-2020 – Key Action 2: Strategic Partnership Projects<br />AGREEMENT n. 2020-1-IT02-KA203-080006
                    </p>
                </IonRow>
                <IonRow style={{ justifyContent: 'center', textAlign: 'center' }} >
                    <IonCol>
                        <a href='/legal/privacy-policy'>Privacy Policy</a>
                    </IonCol>
                </IonRow>
                <IonRow style={{ justifyContent: 'center', marginBottom: '10px' }}>
                    <a href='/legal/terms-conditions'><Trans>Termini e Condizioni</Trans></a>
                </IonRow>
            </IonMenu >
        )
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.access ? true : false,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {

        logout: () => {
            dispatch(authActions.logout());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);