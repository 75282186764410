import { User } from 'firebase/auth';
import { Dispatch } from 'redux';
import { auth } from '../../../firebaseConfig';
import { authConstants } from './constants';

export const authActions = {
    authenticationSuccess,
    failed,
    logoutSuccess,
    logout
};

export const actionCreator = {
    success: (userData: User | null) => { return { type: authConstants.LOGIN_SUCCESS, userData }},
    request: () => { return { type: authConstants.LOGIN_REQUEST } },
    failed: () => { return { type: authConstants.LOGIN_FAILED } },
    logout: () => { return { type: authConstants.LOGOUT } }
};

function authenticationSuccess(userData: User | null) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.success(userData));
    };
}

function failed(message: string) {
    return (dispatch: Dispatch) => {
        console.log('Auth failed message:', message);
        dispatch(actionCreator.failed());
    };
}

function logout() {
    return (dispatch: Dispatch) => {
        auth.signOut()
        .then(() => {
            dispatch(actionCreator.logout());
        })
        .catch(err => {
            console.log("[authentication actions - logout]  error logging out:", err);
        })
    };
}

function logoutSuccess() {
    return actionCreator.logout();
}