import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth,  indexedDBLocalPersistence, initializeAuth, User} from 'firebase/auth';
import { getDatabase, ref, get, update, push } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { connect } from "react-redux";
import { getStorage } from "@firebase/storage";
import { authActions } from "./modules/authentication/store/actions";
import { appVersion } from "./appVersion";
import {
	browserName, deviceType, fullBrowserVersion, isMobile, mobileModel,
	mobileVendor, osName, osVersion
} from "react-device-detect";
import i18n from "./i18n";
import { isPlatform } from "@ionic/react";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
	apiKey: "AIzaSyAFjKd9DG1FY23V0DqSg74hZ4q1duC6CZM",
	authDomain: "t4a-reading-easy.firebaseapp.com",
	databaseURL: "https://t4a-reading-easy-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "t4a-reading-easy",
	storageBucket: "t4a-reading-easy.appspot.com",
	messagingSenderId: "231029019504",
	appId: "1:231029019504:web:fd860c1c002efd897075b5",
	measurementId: "G-80VK7PFELS"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);


export const auth =
	isPlatform("ios") ?
		initializeAuth(app, {
			persistence: indexedDBLocalPersistence
		}) :
		getAuth();
/* auth.languageCode = 'fr'; */
export const database = getDatabase();
export const firestore = getFirestore();
export const functions = getFunctions(undefined, "europe-west1");
export const storage = getStorage();


type Props = {
  authenticationSuccess: (userData: User) => void,
  /* failed: (message: string) => void, */
};

type State = {
};

class Authentication extends React.Component<Props, State> {

  componentDidMount() {
    auth.onAuthStateChanged(async (userData) => {
        /* console.log("[firebaseConfig] User data", userData); */
		if(userData !== null) {
			

			this.props.authenticationSuccess(userData);
			// get user data 

			get(ref(database, `u/${userData.uid}`))
			.then(data => {
				if(!data.val() || !data.val()["e"]) {
					update(ref(database, `u/${userData.uid}`), {
						e: userData.email,
					})
				}

				if(!data.val() || !data.val()["l"]) {
					update(ref(database, `u/${userData.uid}`), {
						l: i18n.language,
					})
				}

				if(data.val() && data.val()["l"]) {
					i18n.changeLanguage(data.val()["l"]);
				}
			})
			.catch(err => {
				console.log("[firebase.tsx] error getting user data:", err);
			});

			const nowTimestamp = Math.round(new Date().getTime() / 1000);
			let deviceInfo = {
				t: deviceType,
				o: osName + " - " + osVersion,
				b: browserName + " - " + fullBrowserVersion,
				m: isMobile ? mobileVendor + " - " + mobileModel : null,
			}
			push(ref(database, `l/u/${userData.uid}/a`), {
				t: nowTimestamp,
				v: appVersion,
				d: deviceInfo,
			})
			.then(() => {
				//console.log("[firebase] a. push success");
			})
			.catch(err => {
				//console.log("[firebase] error logging:", err);
			})
		}
		else {
			console.log("We did not authenticate.");
		}
    });
  }

  render() {
      return (
        <></>
      );
  }
}


const mapDispatchToProps = (dispatch: any) => {
	return {
		authenticationSuccess: (userData: User) => {
			dispatch(authActions.authenticationSuccess(userData));
		},
	}
}

export default connect(null, mapDispatchToProps)(Authentication);