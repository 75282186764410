import {
    IonContent, IonGrid, IonPage, isPlatform,
} from '@ionic/react';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { auth, firestore } from '../../../firebaseConfig';
import SelectionPopover from '../../popover/SelectionPopover';
import { DocumentFontSettings, fromBackendFontNameToCssFontFamily, Highlight, settingsServices } from '../../settings/store/services';
import FileViewerHeader from '../components/FileViewerHeader';
import { collection, onSnapshot, query, where, Unsubscribe } from "firebase/firestore";
import { Document, DocumentElement } from '../store/reducers';
import { filesServices } from '../store/services';
import Lottie from 'react-lottie';
import processingAnimation from "../../../assets/animations/processing.json";
import TtsPlayer from '../components/TtsPlayer';
import { getTTS } from '../../popover/common/ttsServices';
import { franc } from 'franc';
import { ttsServices } from '../../tts/store/services';
import i18n from '../../../i18n';
import "./FileViewer.css";
import ClickPopover from '../../popover/ClickPopover';

type Props = {
    history: any,
    match: any,

    showTutorial: boolean,
}

type State = {
    document: Document | null,
    documentElements: DocumentElement[] | null,

    isLoadingFile: boolean,
    highlightings: Highlight[],
    fontSettings: DocumentFontSettings | undefined,

    //////
    userUuid: string | null,
    scrollOffsetY: number,
    unsubscribeOnFilesChanges: Unsubscribe | null,

    showTtsPlayer: boolean,
    ttsParagraphPointer: number | null,
    audioToPlay: string | undefined,

    showClickPopover: boolean,
    clickedDocumentElement: DocumentElement | null,
}

class FileViewer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            document: null,
            documentElements: null,

            isLoadingFile: false,
            highlightings: [],
            fontSettings: undefined,

            /////
            userUuid: null,
            scrollOffsetY: 0,
            unsubscribeOnFilesChanges: null,

            showTtsPlayer: false,
            ttsParagraphPointer: null,
            audioToPlay: undefined,

            showClickPopover: false,
            clickedDocumentElement: null,
        }
    }

    selectionPopoverRef = createRef();
    showPopoverButton = createRef();

    componentWillUnmount() {
        document.onselectionchange = function () { };
    }

    componentDidMount() {
        
        // console.log('[FileViewer] File id:', this.props.match.params.fileId); // TO REMOVE

        auth.onAuthStateChanged(userData => {
            if (userData) {

                const _this = this;
                if(isPlatform("desktop")) {
                    document.onselectionchange = function (e) {
                        _this.getTextSelection();
                    };
                }

                this.setState({ userUuid: userData.uid });
                if (this.props.match.params.fileId) {
                    this.setState({ isLoadingFile: true });

                    filesServices.getMainFileData(this.props.match.params.fileId)
                    .then(async document => {
                        this.setState({
                            document: document,
                            isLoadingFile: false,
                        });

                        try {
                            // console.log("[File Viewer] Started getting preferences");
                            const highlightings = await settingsServices.getHighlightings(userData.uid);
                            this.setState({
                                highlightings: highlightings,
                                //processedText: this.state.documentElements ? processText(this.state.documentElements, highlightings) : "",
                            });

                            const viewingSettings = await settingsServices.getDocumentViewingSettings(userData.uid);
                            this.setState({ fontSettings: viewingSettings });
                        }
                        catch (e) {
                            console.error('[FileViewer] error getting highlighting preferences or viewing settings:', e)
                        }

                        const documentDataQuery = query(collection(firestore, `f/${this.props.match.params.fileId}/d`), where("d", "==", this.props.match.params.fileId));
                        const unsubscribe = onSnapshot(documentDataQuery, (querySnapshot) => {
                            this.processText(filesServices.fromDocumentDataToDocumentElements(querySnapshot), this.state.highlightings, this.state.fontSettings);
                            this.setState({
                                documentElements: filesServices.fromDocumentDataToDocumentElements(querySnapshot)
                            }, () => {
                                if (this.state.documentElements && this.state.documentElements.length > 0 && this.state.userUuid) {
                                    if (this.state.userUuid) {
                                        ttsServices.getTTSSettings(this.state.userUuid)
                                            .then(ttsSettings => {
                                                getTTS(this.state.documentElements![0].text, "it", ttsSettings)
                                                .then(audio => {
                                                    this.setState({
                                                        audioToPlay: audio as string,
                                                        ttsParagraphPointer: 0,
                                                    });
                                                })
                                                .catch(err => {
                                                    console.error("[FileViewer] error getting tts:", err);
                                                });
                                        })
                                    }
                                }
                            });
                        });
                        this.setState({ unsubscribeOnFilesChanges: unsubscribe });

                    })
                    .catch(err1 => {
                        console.error(`[FileViewer] get file error: ${JSON.stringify(err1)}`);
                        this.setState({
                            isLoadingFile: false,
                        });
                    });
                }
            }
            else {
                this.setState({ userUuid: null });
            }
        })
    }

    processText(documentElements: DocumentElement[], highlightings: Highlight[], fontSettings?: DocumentFontSettings) {
        const divSection = document.createElement("div");
        if(isPlatform("desktop")) {
            divSection.classList.add("fileViewerTextSelectable");
        }
    
        if (fontSettings) {
            divSection.style.fontFamily = fromBackendFontNameToCssFontFamily(fontSettings.font);
            divSection.style.fontSize = `${fontSettings.fontSize}px`;
            divSection.style.lineHeight = `${100 + fontSettings.lineLeading}%`;
        }
    
        for (let i = 0; i < documentElements.length; i++) {
            let paragraph = document.createElement("p");

            if(!isPlatform("desktop")) {
                paragraph.onclick = (e) => {
                    this.setState({
                        showClickPopover: true,
                        clickedDocumentElement: documentElements[i],
                    });
                };
            }

            if (!highlightings.length) {
                paragraph.appendChild(document.createTextNode(documentElements[i].text));
            }
            else {
                for (let j = 0; j < highlightings.length; j++) {
                    if (j === 0) {
                        const splittedStrings = documentElements[i].text.split(highlightings[j].target);
                        if (splittedStrings.length === 1) {
                            paragraph.appendChild(document.createTextNode(documentElements[i].text))
                        }
                        else {
                            for (let k = 0; k < splittedStrings.length; k++) {
                                if (k === 0) {
                                    paragraph.appendChild(document.createTextNode(splittedStrings[k]))
                                }
                                else if (splittedStrings[k].length < 1) {
                                    const span = document.createElement("span");
                                    span.style.color = highlightings[j].color;
                                    span.textContent = highlightings[j].target;
                                    paragraph.appendChild(span);
                                }
                                else {
                                    const span = document.createElement("span");
                                    span.style.color = highlightings[j].color;
                                    span.textContent = highlightings[j].target;
                                    paragraph.appendChild(span);
                                    paragraph.appendChild(document.createTextNode(splittedStrings[k]))
                                }
                            }
                        }
                    }
                    else {
                        const tempParagraph = document.createElement("p");
                        for (let abc = 0; abc < paragraph.childNodes.length; abc++) {
                            const element = paragraph.childNodes[abc];
                            if(element.nodeName === "#text") {
                                const splittedStrings = (element as Text).data.split(highlightings[j].target);
                                if (splittedStrings.length === 1) {
                                    tempParagraph.appendChild(document.createTextNode((element as Text).data))
                                }
                                else {
                                    for (let l = 0; l < splittedStrings.length; l++) {
                                        if (l === 0) {
                                            tempParagraph.appendChild(document.createTextNode(splittedStrings[l]))
                                        }
                                        else if (splittedStrings[l].length < 1) {
                                            const span = document.createElement("span");
                                            span.style.color = highlightings[j].color;
                                            span.textContent = highlightings[j].target;
                                            tempParagraph.appendChild(span);
                                        }
                                        else {
                                            const span = document.createElement("span");
                                            span.style.color = highlightings[j].color;
                                            span.textContent = highlightings[j].target;
                                            tempParagraph.appendChild(span);
                                            tempParagraph.appendChild(document.createTextNode(splittedStrings[l]))
                                        }
                                    }
                                }
                            }
                            else {
                                const clone = paragraph.childNodes[abc].cloneNode(true);                            
                                tempParagraph.appendChild(clone);
                            }
                        }
                        paragraph = tempParagraph;
                    }
                }
            }
            divSection.appendChild(paragraph);
        }
        
        (document.getElementById("fileViewerTextDivContainer") as HTMLDivElement).replaceChildren(divSection);
    }

    getTextSelection() {
        if (window.getSelection) {
            const gotSelection = window.getSelection();
            if (gotSelection) {
                // OR
                // const lngDetector = new (require('languagedetect'));

                if (gotSelection.rangeCount > 0 && gotSelection.toString() !== '') {
                    (this.showPopoverButton.current as any).showPopoverButton(true);
                    console.log(franc(gotSelection.toString(), { only: ['ita', 'fra', 'spa', 'deu'] })); // TO REMOVE
                    (this.selectionPopoverRef.current as any).changeSelectedText(gotSelection.toString());
                    (this.selectionPopoverRef.current as any).setSelection(gotSelection);
                }
                else {
                    (this.showPopoverButton.current as any).showPopoverButton(false);

                    (this.selectionPopoverRef.current as any).changeSelectedText(null);
                    (this.selectionPopoverRef.current as any).setSelection(null);
                }
            }
        }
        else {
            (this.showPopoverButton.current as any).showPopoverButton(false);
            (this.selectionPopoverRef.current as any).changeSelectedText(null);
            (this.selectionPopoverRef.current as any).setSelection(null);
        }
        console.log((this.showPopoverButton.current as any));

    }

    render() {
        return (
            <IonPage>

                <FileViewerHeader
                    popoverButtonClicked={() => {
                        (this.selectionPopoverRef.current as any).showPopover(true);
                    }}
                    ref={this.showPopoverButton as React.LegacyRef<FileViewerHeader>}
                    document={this.state.document}
                    isLoadingFile={this.state.isLoadingFile}
                    showFileViewerPlayer={(show) => {
                        this.setState({ showTtsPlayer: show });
                    }}
                    showPlayer={this.state.showTtsPlayer}
                />

                <IonContent
                    className='fileViewerContent'
                    fullscreen
                    scrollEvents={true}
                    /* onIonScroll={e => this.handleScroll(e)}
                    onIonScrollStart={e => this.scrollStart()} */
                    onIonScroll={e => {
                        (this.selectionPopoverRef.current as any).setScrollOffsetY(e.detail.scrollTop);
                    }}
                >
                    {
                        (!this.state.documentElements ||
                            this.state.documentElements.length === 0) &&
                        <div className="fileViewerProcessingAnimationDiv">
                            <Lottie
                                isClickToPauseDisabled={true}
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: processingAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice"
                                    }
                                }}
                                height={200}
                                width={200}
                            />
                            <p className="fileViewerProcessingAnimationParagraph">
                                Attendi giusto qualche secondo, stiamo processando il tuo documento... {/* TO BE LOCALIZED */}
                            </p>
                        </div>
                    }

                    <IonGrid className='fileViewerTextGrid'>


                        <div id='fileViewerTextDivContainer'>
                        </div>

                    </IonGrid>

                    <SelectionPopover
                        history={this.props.history}
                        ref={this.selectionPopoverRef as React.LegacyRef<SelectionPopover>}
                    />

                    <TtsPlayer
                        showPlayer={this.state.showTtsPlayer}
                        audioToPlay={this.state.audioToPlay}
                        onBackwardPressed={() => {
                            if (this.state.documentElements && this.state.documentElements.length > 0) {
                                const currentPointer = this.state.ttsParagraphPointer ? this.state.ttsParagraphPointer : 0;
                                if ((currentPointer - 1) > -1) {
                                    this.setState({ ttsParagraphPointer: currentPointer - 1 }, () => {
                                        if (this.state.userUuid) {
                                            ttsServices.getTTSSettings(this.state.userUuid)
                                                .then(ttsSettings => {
                                                    if (this.state.documentElements && this.state.ttsParagraphPointer) {
                                                        getTTS(this.state.documentElements[this.state.ttsParagraphPointer].text, "it", ttsSettings)
                                                        .then(audio => {
                                                            this.setState({ audioToPlay: audio });
                                                        })
                                                        .catch(err => {
                                                            console.error("[FileViewer] error getting tts:", err);
                                                        });
                                                    }
                                                })
                                        }
                                    });
                                }
                            }
                            else {
                                this.setState({
                                    ttsParagraphPointer: null,
                                })
                            }
                        }}
                        onForwardPressed={() => {
                            if (this.state.documentElements && this.state.documentElements.length > 0) {
                                const currentPointer = this.state.ttsParagraphPointer ? this.state.ttsParagraphPointer : 0;
                                if (this.state.documentElements.length > (currentPointer + 1)) {
                                    this.setState({ ttsParagraphPointer: currentPointer + 1 }, () => {
                                        if (this.state.userUuid) {
                                            ttsServices.getTTSSettings(this.state.userUuid)
                                                .then(ttsSettings => {
                                                    if (this.state.documentElements && this.state.ttsParagraphPointer) {
                                                        getTTS(this.state.documentElements[this.state.ttsParagraphPointer].text, "it", ttsSettings)
                                                            .then(audio => {
                                                                this.setState({ audioToPlay: audio });
                                                            })
                                                            .catch(err => {
                                                                console.error("[FileViewer] error getting tts:", err);
                                                            });
                                                    }
                                                })
                                        }
                                    });
                                }
                            }
                            else {
                                this.setState({
                                    ttsParagraphPointer: null,
                                })
                            }
                        }}
                        onPlaybackEnded={() => {
                            if (this.state.documentElements && this.state.documentElements.length > 0) {
                                const currentPointer = this.state.ttsParagraphPointer ? this.state.ttsParagraphPointer : 0;
                                if (this.state.documentElements.length > (currentPointer + 1)) {
                                    this.setState({ ttsParagraphPointer: currentPointer + 1 }, () => {
                                        if (this.state.userUuid) {
                                            ttsServices.getTTSSettings(this.state.userUuid)
                                                .then(ttsSettings => {
                                                    if (this.state.documentElements && this.state.ttsParagraphPointer) {
                                                        getTTS(this.state.documentElements[this.state.ttsParagraphPointer].text, "it", ttsSettings)
                                                            .then(audio => {
                                                                this.setState({ audioToPlay: audio });
                                                            })
                                                            .catch(err => {
                                                                console.error("[FileViewer] error getting tts:", err);
                                                            });
                                                    }
                                                })
                                        }

                                    });
                                }
                            }
                            else {
                                this.setState({
                                    ttsParagraphPointer: null,
                                })
                            }
                        }}
                    />


                </IonContent>

                <ClickPopover
                    isOpen={this.state.showClickPopover}
                    documentElement={this.state.clickedDocumentElement}
                    fontSettings={this.state.fontSettings}
                    history={this.props.history}
                    onDidDismiss={() => {
                        this.setState({
                            showClickPopover: false,
                            clickedDocumentElement: null,
                        });
                    }}
                />
            </IonPage >
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showTutorial: state.tutorials.showFileViewerTutorial ? true : false,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);