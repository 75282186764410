export const AuthTranslationsEN = {
    "Registrazione": "Sign Up",
    "Email": "Email address",
    "Ho letto e accetto la": "I have read and agree to the",
    " e i": " and the",
    "termini di servizio": "terms of service",
    "Voglio registrarmi": "Sign Up",
    "Hai già un account? ": "Already have an account? ",
    "Accedi": "Sign In",
    "Iniziamo": "Let's Start",
    "Email non valida": "Email not valid",
    "La password deve essere di almeno 7 caratteri": "Password must be at least 7 characters",
    "Devi prima accettare i termini per la privacy": "You must agree to the privacy terms",

    "Non hai un account?": "Don't have an account yet?",
    "Registrati": "Sign Up",
    "Ho dimenticato la password": "Forgot Password?",

    "Ti abbiamo inviato una mail di conferma a": "We've sent a confirmation link to",

    "Su quale dispositivo vuoi effettuare l'accesso?": "On which device do you want to sign in?",
    "Accedi da questo dispositivo": "Sign In from current device",
    "Accedi da un altro dispositivo": "Sign In from another device",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scan this QR Code from the device you want to use for Reading Easy"
}
export const AuthTranslationsIT = {
    "Registrazione": "Registrazione",
    "Email": "Email",
    "Ho letto e accetto la": "Ho letto e accetto la",
    " e i": " e i",
    "termini di servizio": "termini di servizio",
    "Voglio registrarmi": "Voglio registrarmi",
    "Hai già un account? ": "Hai già un account? ",
    "Accedi": "Accedi",
    "Iniziamo": "Iniziamo",
    "Email non valida": "Email non valida",
    "La password deve essere di almeno 7 caratteri": "La password deve essere di almeno 7 caratteri",
    "Devi prima accettare i termini per la privacy": "Devi prima accettare i termini per la privacy",

    "Non hai un account?": "Non hai un account?",
    "Registrati": "Registrati",
    "Ho dimenticato la password": "Ho dimenticato la password",

    "Ti abbiamo inviato una mail di conferma a": "Ti abbiamo inviato una mail di conferma a",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Su quale dispositivo vuoi effettuare l'accesso?",
    "Accedi da questo dispositivo": "Accedi da questo dispositivo",
    "Accedi da un altro dispositivo": "Accedi da un altro dispositivo",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy."
}
export const AuthTranslationsES = {
    "Registrazione": "Sign Up",
    "Email": "Email address",
    "Ho letto e accetto la": "I have read and agree to the",
    " e i": " and the",
    "termini di servizio": "terms of service",
    "Voglio registrarmi": "Sign Up",
    "Hai già un account? ": "Already have an account? ",
    "Accedi": "Sign In",
    "Iniziamo": "Iniziamo",
    "Email non valida": "Email not valid",
    "La password deve essere di almeno 7 caratteri": "Password must be at least 7 characters",
    "Devi prima accettare i termini per la privacy": "You must agree to the privacy terms",

    "Non hai un account?": "Don't have an account?",
    "Registrati": "Sign Up",
    "Ho dimenticato la password": "Forgot Password?",

    "Ti abbiamo inviato una mail di conferma a": "We've sent a confirmation link to",

    "Su quale dispositivo vuoi effettuare l'accesso?": "On which device do you want to sign in?",
    "Accedi da questo dispositivo": "Sign In from current device",
    "Accedi da un altro dispositivo": "Sign In from another device",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scan this QR Code from the device you want to use for Reading Easy"
}
export const AuthTranslationsFR = {
    "Registrazione": "Sign Up",
    "Email": "Email address",
    "Ho letto e accetto la": "I have read and agree to the",
    " e i": " and the",
    "termini di servizio": "terms of service",
    "Voglio registrarmi": "Sign Up",
    "Hai già un account? ": "Already have an account? ",
    "Accedi": "Sign In",
    "Iniziamo": "Iniziamo",
    "Email non valida": "Email not valid",
    "La password deve essere di almeno 7 caratteri": "Password must be at least 7 characters",
    "Devi prima accettare i termini per la privacy": "You must agree to the privacy terms",

    "Non hai un account?": "Don't have an account?",
    "Registrati": "Sign Up",
    "Ho dimenticato la password": "Forgot Password?",

    "Ti abbiamo inviato una mail di conferma a": "We've sent a confirmation link to",

    "Su quale dispositivo vuoi effettuare l'accesso?": "On which device do you want to sign in?",
    "Accedi da questo dispositivo": "Sign In from current device",
    "Accedi da un altro dispositivo": "Sign In from another device",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scan this QR Code from the device you want to use for Reading Easy"
}