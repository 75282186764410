import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from './common/store';
import Profile from './modules/user/pages/Profile';
import FilesManager from './modules/files/pages/FilesManager';
import FileViewer from './modules/files/pages/FileViewer';
import Settings from './modules/settings/pages/Settings';
import Onboarding from './modules/onboarding/pages/Onboarding';
import CookiePolicy from './modules/legal/pages/CookiePolicy';
import Sketch from './modules/sketch/pages/Sketch';
import PrivacyPolicy from './modules/legal/pages/PrivacyPolicy';
import Support from './modules/user/pages/Support';
import TermsConditions from './modules/legal/pages/TermsConditions';
import Licenses from './modules/legal/pages/Licenses';
import AuthenticationGetStarted from './modules/authentication/pages/AuthenticationGetStarted';
import AuthenticationCompletion from './modules/authentication/pages/AuthenticationCompletion';
import MagicLinkRequest from './modules/authentication/pages/MagicLinkRequest';
import CredentialsLogin from './modules/authentication/pages/CredentialsLogin';
import CredentialsSignup from './modules/authentication/pages/CredentialsSignup';
import AuthenticationSignupOrSigninChoice from './modules/authentication/pages/AuthenticationSignupOrSigninChoice';


function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth.userData ? true : false;
    //console.log('[Router] isAuthenticated:', isAuthenticated);
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <Redirect to="/authentication/get-started" />}
        />
    );
}

function Router() {
    return (

        <IonReactRouter>


            <IonRouterOutlet id="main">
                <Switch>
                    <Route path="/onboarding" component={Onboarding} exact={true} />

                    {/* <Route path="/signup" component={Registration} exact={true} />
                        <Route path="/login" component={Login} exact={true} /> */}

                    <Route
                        exact path="/authentication/get-started"
                        render={(routeProps: any) => <AuthenticationGetStarted  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/email-password-authentication"
                        render={(routeProps: any) => <AuthenticationSignupOrSigninChoice  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/login"
                        render={(routeProps: any) => <CredentialsLogin  {...routeProps} />}
                    />
                    <Route
                        exact path="/authentication/signup"
                        render={(routeProps: any) => <CredentialsSignup  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/magic-link-request"
                        render={(routeProps: any) => <MagicLinkRequest  {...routeProps} />}
                    />

                    <Route
                        exact path="/authentication/completion"
                        render={(routeProps: any) => <AuthenticationCompletion  {...routeProps} />}
                    />

                    <Route exact path="/support" component={Support} />

                    <PrivateRoute
                        exact path="/profile"
                        render={(routeProps: any) => <Profile  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/files/my-files"
                        render={(routeProps: any) => <FilesManager  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path='/file/:fileId'
                        render={(routeProps: any) => <FileViewer  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path='/settings'
                        render={(routeProps: any) => <Settings  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path='/sketch'
                        render={(routeProps: any) => <Sketch  {...routeProps} />}
                    />

                    <Route path='/legal/cookie-policy' component={CookiePolicy} exact={true} />
                    <Route path='/legal/privacy-policy' component={PrivacyPolicy} exact={true} />
                    <Route path='/legal/terms-conditions' component={TermsConditions} exact={true} />
                    <Route path='/legal/licenses' component={Licenses} exact={true} />


                    <Redirect to="/files/my-files" />
                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;