import { tutorialsConstants } from './constants';
import { Dispatch } from 'redux';

export const tutorialsActions = {
    showOnboarding,
    showFileViewerTutorial,
};

export const actionCreator = {
    showOnboarding: (show: boolean) => { return { type: tutorialsConstants.SHOW_ONBOARDING, show } },
    showFileViewerTutorial: (show: boolean) => { return { type: tutorialsConstants.SHOW_FILE_VIEWER_TUTORIAL, show } },
};


function showOnboarding(show: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.showOnboarding(show));
    };
}

function showFileViewerTutorial(show: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.showFileViewerTutorial(show));
    };
}