import {
    IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonGrid, IonHeader, IonPage,
    IonTitle, IonToolbar
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import './Licenses.css';

type Props = {
    history: any,
}

type State = {
}

class Licenses extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref='/' />
                        </IonButtons>
                        {/* <IonButtons slot='start'>
                            <IonMenuButton/>
                    </IonButtons> */}

                        <IonTitle>Licenze</IonTitle>
                    </IonToolbar>
                </IonHeader>


                <IonContent fullscreen>
                    <IonTitle style={{ fontSize: '40px', textAlign: 'center' }}>Licenze</IonTitle>
                    <IonGrid className='supportGrid'>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Icona tap FileManager</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <a href='https://lottiefiles.com/23081-tap' target="_blank" rel="noopener noreferrer">https://lottiefiles.com</a>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Long press FileViewer</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <a href='https://lottiefiles.com/2753-hold' target="_blank" rel="noopener noreferrer">https://lottiefiles.com</a>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Check Email Confirmation</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <a href='https://lottiefiles.com/1798-check-animation' target="_blank" rel="noopener noreferrer">https://lottiefiles.com</a>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>QR Code scan animation</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <a href='https://lottiefiles.com/61155-scan-qr-code' target="_blank" rel="noopener noreferrer">https://lottiefiles.com</a>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Devices Icon</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                Icons made by <a href="https://www.flaticon.com/authors/dimitry-miroliubov" title="Dimitry Miroliubov">Dimitry Miroliubov</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Icona marker</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                "highlighter" by Shmidt Sergey from the Noun Project
                                <br />
                                <a href='https://thenounproject.com/search/?q=highlighter&i=576586' target="_blank" rel="noopener noreferrer">https://thenounproject.com</a>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Icona sketch:</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                "Created by EchoEternal from the Noun Project"
                                <br />
                                <a href='https://thenounproject.com/echoeternal/collection/design-system/?i=2006811' target="_blank" rel="noopener noreferrer">https://thenounproject.com</a>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Font EasyReading®</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                Contratto fra Tech4All s.r.l. e EasyReading Multimedia s.r.l. del 16 maggio 2022.<br /> {/* TO EDIT THIS */}
                            </IonCardContent>
                        </IonCard>

                    </IonGrid>



                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Licenses);