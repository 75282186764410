export const FileTranslationsEN = {
    "Gestore dei File":"File Manager",
    "Ordine alfabetico": "Alphabetical order",
    "Stai filtrando per tag:": "Filter by tags:",
    "Dal più recente": "Newest",
    "Dal meno recente": "Oldest",
    "Cerca file": "Search file",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.":"Allow this device to receive push notifications. Tap here.",
    "Carica il tuo primo documento":"Upload your first document",
}
export const FileTranslationsIT = {
    "Gestore dei File":"Gestore dei File",
    "Salva": "Salva",
    "Ordine alfabetico": "Ordine alfabetico",
    "Stai filtrando per tag:": "Stai filtrando per tag:",
    "Dal più recente": "Dal più recente",
    "Dal meno recente": "Dal meno recente",
    "Indietro": "Indietro",
    "Avanti": "Avanti",
    "Pulisci": "Pulisci",
    "Disegna": "Disegna",
    "Cancella": "Cancella",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.":"Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.",
    "Carica il tuo primo documento":"Carica il tuo primo documento",

}
export const FileTranslationsES = {
    "Gestore dei File":"File Manager",
    "Ordine alfabetico": "Alphabetical order",
    "Stai filtrando per tag:": "Filter by tags:",
    "Dal più recente": "Newest",
    "Dal meno recente": "Oldest",
    "Cerca file": "Search file",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.":"Allow this device to receive push notifications. Tap here.",
    "Carica il tuo primo documento":"Upload your first document",

}
export const FileTranslationsFR = {
    "Gestore dei File":"File Manager",
    "Ordine alfabetico": "Alphabetical order",
    "Stai filtrando per tag:": "Filter by tags:",
    "Dal più recente": "Newest",
    "Dal meno recente": "Oldest",
    "Cerca file": "Search file",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.":"Allow this device to receive push notifications. Tap here.",
    "Carica il tuo primo documento":"Upload your first document",
}