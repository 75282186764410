import {
    IonContent, IonButton, IonInput, IonTitle, IonPage,
    IonToolbar, IonHeader, IonGrid, IonAlert, IonToggle, IonButtons, IonIcon, IonBackButton, IonLabel, IonItem, IonImg
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import './CredentialsLogin.css';
import { auth } from '../../../firebaseConfig';
import Lottie from 'react-lottie';
import loginAnimation from '../../../assets/animations/login.json'

type Props = {
    history: any,

    isAuthenticated?: boolean,
};

type State = {
    email: string | null,
    password: string | null,

    legalTermsAccepted: boolean,

    showResetPasswordButton: boolean,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string,
    errorAlertMessage: string,
    showSupportButton: boolean,

};

class CredentialsSignup extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: null,
            password: null,

            legalTermsAccepted: false,

            showResetPasswordButton: false,

            showErrorAlert: false,
            errorAlertHeaderMessage: "",
            errorAlertMessage: "",
            showSupportButton: false,

        }
    }

    render() {
        const animationOptions = {
            loop: true,
            autoplay: true,
            animationData: loginAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>
                            Registrati {/* TO BE LOCALIZED */}
                        </IonTitle>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref='/authentication/email-password-authentication' />
                        </IonButtons>
                        {
                            this.state.showSupportButton &&
                            <IonButtons slot='end'>
                                <IonButton
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        window.open('https://wa.me/393661126799');
                                    }}
                                >
                                    {/* <IonIcon
                                        size='large'
                                        icon={supportPersonIcon}
                                    /> */}
                                </IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="getStartedMainGrid">
                        <div className='getStartedLottieDiv'>
                            <Lottie
                                isClickToPauseDisabled={true}
                                options={animationOptions}
                                height={300}
                                width={300}
                            />
                        </div>
                        <div className="getStartedInputsDiv">
                            <p className="authGetStartedSubTitle">
                                Inserisci un'email e una password per registrarti {/* TO BE LOCALIZED */} {/* TO EDIT */}
                            </p>
                            <div>
                                <IonItem>
                                    <IonLabel position='floating'>Email</IonLabel>
                                    <IonInput
                                        className="getStartedInputField"
                                        placeholder="La tua email" /* TO BE LOCALIZED */
                                        type="email"
                                        autocomplete="email"
                                        inputMode="email"
                                        value={this.state.email}
                                        onIonChange={(e) => {
                                            this.setState({ email: e.detail.value ? e.detail.value : null });
                                        }}
                                    />
                                </IonItem>
                                <IonItem>
                                    <IonLabel position='floating'>Password</IonLabel>
                                    <IonInput
                                        className="getStartedInputField"
                                        placeholder="Password" /* TO BE LOCALIZED */
                                        type="password"
                                        inputMode="text"
                                        value={this.state.password}
                                        onIonChange={(e) => {
                                            this.setState({ password: e.detail.value ? e.detail.value : null });
                                        }}
                                    />
                                </IonItem>

                                <div className="getStartedLegalTermsDiv">
                                    <IonToggle
                                        checked={this.state.legalTermsAccepted}
                                        onIonChange={e => {
                                            this.setState({ legalTermsAccepted: e.detail.checked });
                                        }}
                                    />
                                    <p className="getStartedLegalTermsParagraph">
                                        Accetto la <a
                                            href='https://estro.ai/maestro/privacy-policy'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                        >
                                            privacy policy
                                        </a> e i <a
                                            href='https://estro.ai/maestro/terms-conditions'
                                            target='_blank'
                                            rel="noopener noreferrer"
                                        >
                                            termini di servizio{/* TO BE LOCALIZED */}
                                        </a>.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="getStartedButtonsDiv">
                            <IonButton
                                disabled={!this.state.legalTermsAccepted}
                                className="getStartedButton"
                                onClick={() => {
                                    if (this.state.password === '' || !this.state.password) {
                                        this.setState({
                                            showErrorAlert: true,
                                            errorAlertHeaderMessage: "Password non valida", /* TO BE LOCALIZED */
                                            errorAlertMessage: "Assicurati di aver inserito una password", /* TO BE LOCALIZED */
                                        });
                                        return
                                    }
                                    if (this.state.email === '' || !this.state.email) {
                                        this.setState({
                                            showErrorAlert: true,
                                            errorAlertHeaderMessage: "Email non valida", /* TO BE LOCALIZED */
                                            errorAlertMessage: "Assicurati di aver inserito una email", /* TO BE LOCALIZED */
                                        });
                                        return
                                    }
                                    
                                    if (this.state.legalTermsAccepted && this.state.email && this.state.password) {
                                        createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
                                        .then(uc => {
                                            //console.log("[GetStarted] user created. Uid:", uc.user.uid);
                                        })
                                        .catch(err => {
                                            if (err.code === "auth/weak-password") {
                                                this.setState({
                                                    showErrorAlert: true,
                                                    errorAlertHeaderMessage: "Password debole", /* TO BE LOCALIZED */
                                                    errorAlertMessage: "Prova ad inserire altri caratteri e/o ad usare simboli.", /* TO BE LOCALIZED */
                                                });
                                            }
                                            else if (err.code === "auth/internal-error") {
                                                this.setState({
                                                    showErrorAlert: true,
                                                    errorAlertHeaderMessage: "Errore interno", /* TO BE LOCALIZED */
                                                    errorAlertMessage: "Contatta il supporto cliccando l'icona in alto a destra.", /* TO BE LOCALIZED */
                                                });
                                            }
                                            else if (err.code === "auth/too-many-requests") {
                                                this.setState({
                                                    showErrorAlert: true,
                                                    errorAlertHeaderMessage: "Troppe richieste", /* TO BE LOCALIZED */
                                                    errorAlertMessage: "Contattare il supporto o ritentare tra un po'.", /* TO BE LOCALIZED */
                                                });
                                            }
                                        })
                                    }
                                }}
                            >
                                Iniziamo {/* TO BE LOCALIZED */}
                            </IonButton>

                        </div>
                    </IonGrid>

                    {
                        this.props.isAuthenticated &&
                        <Redirect to="/" />
                    }

                </IonContent>

                <IonAlert
                    isOpen={this.state.showErrorAlert}
                    cssClass='getStartedErrorAlert'
                    onDidDismiss={() => {
                        this.setState({
                            showErrorAlert: false,
                            errorAlertHeaderMessage: "",
                            errorAlertMessage: "",
                        });
                    }}
                    keyboardClose={true}
                    header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
                    message={`<p>${this.state.errorAlertMessage}</p>`} /* TO BE LOCALIZED */
                    buttons={["Ok"]}
                />

            </IonPage>

        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CredentialsSignup);
