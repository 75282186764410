import {
    IonButton, IonHeader, IonImg, IonInput, IonPage, IonTitle,
    IonToolbar
} from '@ionic/react';
import React from 'react';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { connect } from 'react-redux';
import "./AuthenticationCompletion.css";
import { auth } from '../../../firebaseConfig';
import not_found from '../../../assets/animations/not-found.gif'

type Props = {
    history: any,

    logout: () => void,
};

type State = {
    email: string,
    isEmailInputVisibile: boolean,
    linkErrorVisible: boolean,
    expiredLinkErrorVisible: boolean,
};

class AuthenticationCompletion extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            isEmailInputVisibile: false,
            linkErrorVisible: false,
            expiredLinkErrorVisible: false,
        }
    }

    componentDidMount(): void {

        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // email = window.prompt('Please provide your email for confirmation');
                this.setState({ isEmailInputVisibile: true })
            }

            if (email) {

                signInWithEmailLink(auth, email, window.location.href)
                    .then((result) => {
                        // Clear email from storage.
                        console.log("[MagicLink] signin result:", result);
                        window.localStorage.removeItem('emailForSignIn');

                        setTimeout(() => {
                            this.props.history.replace("/files/my-files");
                        }, 3000);
                    })
                    .catch((error) => {
                        console.error("[MagicLink] error signin in wih email link", error.code, error.message);
                    });
            }
        }

    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            Conferma la email
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="authenticationCompletionOuterDiv">
                    <div className="authenticationCompletionInnerDiv">
                        {
                            this.state.isEmailInputVisibile &&
                            <div className="authenticationCompletionInsertEmailDiv">
                                <IonTitle className='authenticationCompletionTitle'>
                                    Ci risulta che questo sia un dispositivo o browser diverso da quello da cui è stata effettuata la richiesta di accesso.<br /><br />
                                </IonTitle>
                                <p>

                                    Per favore, conferma la email che hai inserito in fase di accesso.
                                </p>
                                <p className="authenticationCompletionPointerParagraph">
                                    <span
                                        role="img"
                                        aria-label="finger pointing down emoji"
                                    >
                                        👇
                                    </span>
                                </p>
                                <IonInput
                                    className="authenticationCompletionInsertEmailInput"
                                    autocomplete="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onIonChange={e => {
                                        this.setState({ email: e.detail.value ? e.detail.value : "" });
                                    }}
                                />
                            </div>
                        }

                        {
                            (!this.state.linkErrorVisible && !this.state.expiredLinkErrorVisible)
                            &&
                            <div className="authenticationCompletionConfirmEmailDiv">
                                <IonButton
                                    className="authenticationCompletionConfirmEmailButton"
                                    onClick={() => {
                                        console.log("[AuthenticationCompletion] is it a valid link?", isSignInWithEmailLink(auth, window.location.href))
                                        if (isSignInWithEmailLink(auth, window.location.href)) {
                                            // Additional state parameters can also be passed via URL.
                                            // This can be used to continue the user's intended action before triggering
                                            // the sign-in operation.
                                            // Get the email if available. This should be available if the user completes
                                            // the flow on the same device where they started it.


                                            signInWithEmailLink(auth, this.state.email, window.location.href)
                                            .then((result) => {
                                                // Clear email from storage.
                                                window.location.replace('/files/my-files');
                                                window.localStorage.removeItem('emailForSignIn');
                                                // You can access the new user via result.user
                                                // Additional user info profile not available via:
                                                // result.additionalUserInfo.profile == null
                                                // You can check if the user is new or existing:
                                                // result.additionalUserInfo.isNewUser
                                                console.log("[AuthenticationCompletion] Success signin in with email link:", result);
                                            })
                                            .catch((error) => {
                                                // Some error occurred, you can inspect the code: error.code
                                                // Common errors could be invalid email and invalid or expired OTPs.
                                                console.log("[AuthenticationCompletion] error signin in with email link:", error, "error code:", error.code);
                                                if (error.code === "auth/invalid-action-code") {
                                                    this.setState({
                                                        expiredLinkErrorVisible: true,
                                                        isEmailInputVisibile: false,
                                                    });
                                                }
                                            });
                                        }
                                    }}
                                >
                                    Confermo la mia email
                                </IonButton>
                            </div>
                        }

                        {
                            this.state.linkErrorVisible &&
                            <div className="authenticationCompletionErrorDiv">
                                Link non valido richiedine uno nuovo nella <a href="/">pagina iniziale</a>.
                            </div>
                        }

                        {
                            this.state.expiredLinkErrorVisible &&
                            <div className="authenticationCompletionErrorDiv">
                                <IonTitle className='authenticationCompletionErrorText'>Link scaduto, richiedine uno nuovo nella <a href="/">pagina iniziale</a>.</IonTitle>
                            </div>
                        }


                    </div>
                </div>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationCompletion);