import {
    IonContent, IonButton, IonTitle, IonPage, IonToolbar,
    IonHeader, IonGrid, IonButtons, IonBackButton,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Lottie from 'react-lottie';
import loginAnimation from '../../../assets/animations/login.json';
import './CredentialsLogin.css';

type Props = {
    history: any,

    isAuthenticated?: boolean,
};

type State = {
    email: string | null,
    password: string | null,

    showLegalTerms: boolean,
    legalTermsAccepted: boolean,

    showResetPasswordButton: boolean,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string,
    errorAlertMessage: string,
    showSupportButton: boolean,

};

class AuthenticationSignupOrSigninChoice extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: null,
            password: null,

            showLegalTerms: false,
            legalTermsAccepted: false,

            showResetPasswordButton: false,

            showErrorAlert: false,
            errorAlertHeaderMessage: "",
            errorAlertMessage: "",
            showSupportButton: false,

        }
    }

    render() {
        const animationOptions = {
            loop: true,
            autoplay: true,
            animationData: loginAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <IonPage>
                <IonHeader mode="md">
                    <IonToolbar>
                        <IonTitle>Iniziamo</IonTitle>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref='/' />
                        </IonButtons>
                        {
                            this.state.showSupportButton &&
                            <IonButtons slot='end'>
                                <IonButton
                                    fill='clear'
                                    shape='round'
                                    onClick={() => {
                                        window.open('https://wa.me/393661126799');
                                    }}
                                >
                                    {/* <IonIcon
                                        size='large'
                                        icon={supportPersonIcon}
                                    /> */}
                                </IonButton>
                            </IonButtons>
                        }
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="getStartedMainGrid">
                        <div className='getStartedLottieDiv'>
                            <Lottie
                                isClickToPauseDisabled={true}
                                options={animationOptions}
                                height={300}
                                width={300}
                            />
                        </div>
                        <div className="getStartedInputsDiv">
                            <IonButton
                                onClick={() => {
                                    this.props.history.push(`/authentication/signup`);
                                }}
                            >
                                Registrati {/* TO BE LOCALIZED */}
                            </IonButton>
                            <IonButton
                                fill="clear"
                                onClick={() => {
                                    this.props.history.push(`/authentication/login`);
                                }}
                            >
                                Accedi {/* TO BE LOCALIZED */}
                            </IonButton>
                        </div>
                    </IonGrid>

                </IonContent>
                
                {
                    this.props.isAuthenticated &&
                    <Redirect to="/" />
                }
            </IonPage>

        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationSignupOrSigninChoice);
