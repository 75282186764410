import { IonButton, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { cookieManager } from '../utils/cookieManager';
import './CookieAlert.css';

type Props = {
};

type State = {
    showAlert: boolean,
};


class CookieAlert extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showAlert: false,
        }
    }

    componentDidMount() {
        const cookieConsent = cookieManager.getCookie('cnst');
        //console.log("WINDOW LOCATION", window.location)
        if (window.location.pathname === "/legal/privacy-policy") {
            this.hideBanner();
        }
        else {
            if (!cookieConsent) {
                this.setState({
                    showAlert: true,
                })
                this.showBanner();
            }
            else {
                this.setState({
                    showAlert: false,
                })
                this.hideBanner();
            }
        }
    }

    showBanner() {
        (document.getElementById('cookieAlertMainDiv') as HTMLDivElement).style.visibility = 'visible';
        (document.getElementById('cookieAlertMainDiv') as HTMLDivElement).style.zIndex = '10000';
    }

    hideBanner() {
        this.setState({
            showAlert: false,
        });
        (document.getElementById('cookieAlertMainDiv') as HTMLDivElement).style.visibility = 'hidden';
        (document.getElementById('cookieAlertMainDiv') as HTMLDivElement).style.zIndex = '-1';
    }

    render() {
        return (
            <div
                className='cookieAlertMainDiv'
                id='cookieAlertMainDiv'
            >
                {
                    this.state.showAlert &&
                    <div
                        className='cookieAlertDiv'
                    >
                        <IonGrid className='cookieAlertPolicyGrid'>
                            <IonRow>
                                <h3>
                                    Informativa
                                </h3>
                            </IonRow>

                            <IonRow>
                                <p>
                                    Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato
                                    nella <a href='/legal/cookie-policy'>cookie policy</a>. Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa
                                    informativa, proseguendo la navigazione di questa pagina, interagendo con un link o un
                                    pulsante al di fuori di questa informativa o continuando a navigare in altro modo.
                                </p>
                            </IonRow>

                            <IonRow>
                                <IonButton
                                    className='cookieAlertAcceptButton'
                                    onClick={() => {
                                        cookieManager.setCookie('cnst', 'true');
                                        this.hideBanner();
                                    }}
                                    fill='clear'
                                >
                                    Accetto
                                </IonButton>
                            </IonRow>
                        </IonGrid>

                    </div>
                }
            </div>
        );
    }
}

export default CookieAlert;